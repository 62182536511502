import React from 'react';
import styled from "styled-components";

const AppStoreBadge = () => {
    return (
        <BadgeDiv>
            <A
                href="https://apps.apple.com/us/app/group-khatam-tracker/id1559596288?itsct=apps_box&amp;itscg=30200">
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1616803200&h=35eeebe679d9a67116ed4c58c2cbbcb7"
                        alt="Download on the App Store"/>
                </A>
        </BadgeDiv>

    );
};

export default AppStoreBadge;


const BadgeDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const A = styled.a`
  display: inline-block; 
  overflow: hidden; 
  border-radius: 13px; 
  width: 250px; 
  height: 83px;
  max-width: 90%;

  
  img {
    border-radius: 13px; 
    width: 250px; 
    height: 83px;  
  }
`;
