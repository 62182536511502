import React from 'react';
import {useSelector} from "react-redux";
import {getItemDescription} from "../../../utils/itemDescription";
import {useNavigate} from "@reach/router";
import iconSet from "../../../assets/icomoon/selection.json";
import IcomoonReact from "icomoon-react";
import Header from "../../../components/Header";
import styled from "styled-components";
import ShowAppStoreBadge from "./showAppStoreBadge";

const GroupListView = (props) => {
    const group = useSelector(state => state.khatams.group);
    const groupEntries = group?.entries || [];
    const navigate = useNavigate();

    const navigateToKhatam = (quraanId) => {
        navigate(`/quraan/${quraanId}`)
    };

    const navigateToTasbeeh = (tasbeehId) => {
        navigate(`/tasbeeh/${tasbeehId}`)
    };

    const renderGroupItem = (item) => {
        const groupId = item.id;
        return (
            <ListItem key={groupId} onClick={() => {
                navigate(`/group/${groupId}`)
                if (item.PK === 'quraan') {
                    navigateToKhatam(item.id)
                }
                if (item.PK === 'tasbeeh') {
                    navigateToTasbeeh(item.id)
                }
            }}>
                <SavedGroupsListItem>
                    <IconDiv>
                        <IcomoonReact
                            icon={item.PK}
                            iconSet={iconSet}
                            size={40}
                            color={"#666666"}
                        />
                    </IconDiv>
                    <SavedGroupsDescription>
                        {getItemDescription(group.description, item.description)}
                    </SavedGroupsDescription>
                    <SavedGroupsAdded>
                        <SavedGroupsDate>
                            {new Date(item.created_at).toLocaleDateString()}
                        </SavedGroupsDate>
                        <SavedGroupsTime>
                            {new Date(item.created_at).toLocaleTimeString()}
                        </SavedGroupsTime>
                    </SavedGroupsAdded>
                </SavedGroupsListItem>
            </ListItem>
        );
    }

    const renderEmptyList = () => {
        if (group?.created_by_id || group?.group_admins || group?.members_allowed_to_create) {
            return (
                <GroupsDiv>
                    <Title>There are currently no items in this group</Title>
                    <br/>
                    <ShowAppStoreBadge
                        title={"To add items to a group, you need to download the Group Khatam Tracker App"}
                    />
                </GroupsDiv>
            );
        }
        return (
            <GroupsDiv>
                <Header title={'Your Saved Groups'} back={"/"}/>
                <Title>There are currently no items in this group</Title>
                <br/>
                <Title>Only the group Administrator can create Khatams in this group</Title>
            </GroupsDiv>
        );
    };

    const renderGroupItems = () => {
        if (groupEntries.length < 1) return renderEmptyList();

        let groupsToRender = groupEntries.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at)));
        return groupsToRender.map((group) => {
            return renderGroupItem(group)
        })
    };

    return (
        <Container>
            <GroupsDiv>
                {renderGroupItems()}
                <LastChild/>
            </GroupsDiv>

        </Container>
    );
};

export default GroupListView;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
  :last-child {
      border-bottom: 1px solid #4a4949;
  }
`;

const GroupsDiv = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;

const LastChild = styled.div`
  width: 100%;
  border-bottom: 1px solid #4a4949;
`;

const ListItem = styled.div`
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #4a4949;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
`;


const SavedGroupsListItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const SavedGroupsDescription = styled.div`
  flex: 7;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  padding-left: 10px;
  min-width: 0;
`;

const SavedGroupsAdded = styled.div`
  flex: 3;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SavedGroupsDate = styled.div``;

const SavedGroupsTime = styled.div``;
