import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from "react-redux";
import {completeJuz, reserveJuz} from "../../../../redux/actions/khatams";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

function ActionsDialog(props) {
    const {open, quraanId, juzNumber, setActiveJuzNumber, setDialog} = props;
    const loadedQuraan = useSelector(state => state.khatams.quraan);
    const userId = useSelector(state => state.localStorage.userId);
    const user = useSelector(state => state.localStorage.userName);
    const currentJuz = loadedQuraan[juzNumber];
    const userStartedKhatam = loadedQuraan.created_by_id;
    const juzCompleted = currentJuz?.completed === true;
    const dispatch = useDispatch()

    let actions;

    if (userStartedKhatam) {
        if (juzCompleted) {
            actions = [
                "Clear Reservation",
                "Edit Name"
            ]
        } else {
            actions = [
                "Mark Complete",
                "Clear Reservation",
                "Edit Name"
            ]
        }

    } else {
        if (juzCompleted) {
            actions = [
                "Clear Reservation"
            ]
        } else {
            actions = [
                "Mark Complete",
                "Clear Reservation"
            ]
        }
    }

    const handleClose = () => {
        setActiveJuzNumber(null);
        setDialog(null);
    }

    const handleListItemClick = (value) => {
        if (value === "Mark Complete") {
            dispatch(completeJuz(quraanId, user, userId, juzNumber));
            handleClose()
        }
        if (value === "Clear Reservation") {
            dispatch(reserveJuz(false, quraanId, user, userId, juzNumber));
            handleClose()
        }
        if (value === "Edit Name") {
            setDialog('editName')
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Actions</DialogTitle>
            <List>
                {actions.map((action) => (
                    <ListItem onClick={() => handleListItemClick(action)} key={action}>
                        <ListItemText primary={action}/>
                    </ListItem>
                ))}

            </List>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(ActionsDialog)