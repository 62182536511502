import React from 'react';
import '../../App.css';
import {Fab} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import styled from "styled-components";
import {useNavigate} from "@reach/router"
import {useSelector} from "react-redux";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {getModifiedDateItem} from "../../utils/getModifiedDate";
import RegisterNotificationListItem from "../../components/registerNotificationListItem";

export const QuraanList = () => {
    const savedKhatams = useSelector(state => state.localStorage.savedKhatams);

    const navigate = useNavigate();

    const navigateToNew = () => {
        navigate('/quraan/new')
    };

    const renderSavedKhatamItems = () => {
        let khatamList = Object.keys(savedKhatams).map((khatamId) => {
            let khatam = savedKhatams[khatamId];
            khatam['id'] = khatamId;

            if (!khatam['modified']){
                khatam['modified'] = getModifiedDateItem(khatam)['modified']
            }

            return khatam
        })

        return khatamList.sort((a, b) => (new Date(b.modified) - new Date(a.modified)))
            .map(khatam => {
                let khatamId = khatam.id;
                return (
                    <ListItem key={khatamId} onClick={() => {
                        navigate(`/quraan/${khatamId}`)
                    }}>
                        <SavedKhatamListItem>
                            <SavedKhatamDescription>
                                {savedKhatams[khatamId].description}
                            </SavedKhatamDescription>
                            <SavedKhatamAdded>
                                <SavedKhatamDate>
                                    {savedKhatams[khatamId].modified ?
                                        new Date(savedKhatams[khatamId].modified).toLocaleDateString()
                                        :
                                        savedKhatams[khatamId].addedDate}
                                </SavedKhatamDate>
                                <SavedKhatamTime>
                                    {savedKhatams[khatamId].modified ?
                                        new Date(savedKhatams[khatamId].modified).toLocaleTimeString()
                                        :
                                        savedKhatams[khatamId].addedTime}
                                </SavedKhatamTime>
                            </SavedKhatamAdded>
                        </SavedKhatamListItem>
                    </ListItem>
                );
            })
    };

    const renderSavedKhatams = () => {
        if (Object.keys(savedKhatams).length < 1)
            return (
                <QuraanDiv>
                    <Title>Click on the Add Button to start a new Khatam</Title>
                </QuraanDiv>
            );

        return (
            <QuraanDiv>
                <JuzDiv>
                    {renderSavedKhatamItems()}
                    <LastChild/>
                </JuzDiv>
            </QuraanDiv>
        );
    };

    return (
        <Container className="DarkMode">
            <NewKhatamDiv>
                <Header title={'Your Saved Khatams'}/>
                <RegisterNotificationListItem/>
                {renderSavedKhatams()}
                <Fab
                    onClick={() => navigateToNew()}
                    color="secondary" aria-label="add" style={{position: "absolute", bottom: "100px", right: "5%"}}>
                    <AddIcon/>
                </Fab>
                <Footer bottomBar/>
            </NewKhatamDiv>
        </Container>
    );
}

export default QuraanList;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`;

const NewKhatamDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;


const JuzDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
`;

const LastChild = styled.div`
  width: 100%;
  border-bottom: 1px solid #4a4949;
`;

const QuraanDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: center;
  justify-content: flex-start;
`;

const ListItem = styled.div`
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #4a4949;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
`;


const SavedKhatamListItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const SavedKhatamDescription = styled.div`
  flex: 7;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  padding-left: 10px;
  min-width: 0;
`;

const SavedKhatamAdded = styled.div`
  flex: 3;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SavedKhatamDate = styled.div``;

const SavedKhatamTime = styled.div``;