import React, {useEffect} from 'react';
import '../../App.css';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {toggleFavouriteKhatam} from "../../../redux/actions/localStorage";
import FavouriteButton from "../../components/FavouriteButton";
import {clearLoadedQuraan, fetchKhatam, khatamOpened} from "../../../redux/actions/khatams";
import ShareButton from "../../components/ShareButton";
import Header from "../../components/Header";
import RenderParas from "./components/RenderParas";
import {isCompleted} from "./quraanUtils";
import TimesCompleted from "../../components/TimesCompleted";
import Intention from "../../components/Intention";
import RestartButton from "../../components/RestartButton";
import Loading from "../../components/Loading";

export const Quraan = (props) => {
    const quraanId = props.quraanId;
    const savedKhatams = useSelector(state => state?.localStorage?.savedKhatams);
    const loadedQuraan = useSelector(state => state.khatams.quraan);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.localStorage.userId);

    useEffect(() => {
        dispatch(fetchKhatam(quraanId, userId, !savedKhatams[quraanId]))
        return (() => {
            dispatch(clearLoadedQuraan())
        })
    }, [dispatch, quraanId, userId]);

    useEffect(() => {
        if (!loadedQuraan) return;
        dispatch(khatamOpened(loadedQuraan));
    }, [dispatch, loadedQuraan])

    return (
        <Loading item={loadedQuraan} type={"Khatam"}>
            <Container className="DarkMode">
                <Header title={"Quraan Khatam"} back={"/quraan"}>
                    <ShareButton item={loadedQuraan} type={'Khatam'}/>
                    <FavouriteButton
                        item={loadedQuraan}
                        toggle={toggleFavouriteKhatam}
                        type={'Khatam'}
                        complete={isCompleted(loadedQuraan)}
                    />
                </Header>
                <TimesCompleted item={loadedQuraan} type={"khatam"}/>
                <Intention item={loadedQuraan} type={"Khatam"}/>
                <RestartButton completed={isCompleted(loadedQuraan)} type={"khatam"} item={loadedQuraan}/>
                <RenderParas quraanId={quraanId}/>
            </Container>
        </Loading>

    );
}

export default Quraan;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;




