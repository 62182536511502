import React from 'react';
import styled from "styled-components";
import {useNavigate} from "@reach/router";
import {PRIMARY_THEME_COLOR} from "../const";

const MenuIcon = ({src, title, path, onSelect}) => {

    const navigate = useNavigate();

    const onClick = () => {
        if (path !== "#")
            navigate(path)
        else
            onSelect()
    }

    return (
        <IconDiv onClick={onClick}>
            <IconImg src={src}/>
            <IconText>{title}</IconText>
        </IconDiv>
    );
};

export default MenuIcon;

const IconDiv = styled.div`
    flex: 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const IconImg = styled.img`
    border-radius: 5px;
    border: 1px solid ${PRIMARY_THEME_COLOR};
    min-width: 68px;
    min-height: 68px;
    max-width: 66px;
    max-height: 66px;
`;

const IconText = styled.p`
    text-align: center;
    color: #fff;
`;