import React from 'react';
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const IntentionCapture = ({intention, setIntention, type, fieldName="Intention", title=null}) => {
    if (!fieldName) fieldName = 'Intention';

    return (
        <TextInputGroup>
            {
                fieldName === 'Intention' ?
                    <Title>Please enter the intention for the {type}</Title>
                    :
                    <Title>{title}</Title>
            }
            <WideText
                id="outlined-basic"
                label={fieldName}
                error={!!intention && (intention.length > 256 || intention.length < 4)}
                helperText={
                    (!!intention ?
                        ((intention.length > 256) ? `${fieldName} must be less than 256 characters` :
                            ((intention.length < 4) ? `${fieldName} must be at least 4 characters` : "")) : "")}
                color={"secondary"}
                multiline={true}
                variant="outlined"
                value={intention}
                onChange={(e) => setIntention(e.target.value)}
            />
        </TextInputGroup>
    );
};

export default React.memo(IntentionCapture);

const TextInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
`;

const WideText = styled(TextField)`
  width: 100%
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;