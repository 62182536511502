import React from 'react';
import Counter from "../Counter";
import styled from "styled-components";
import {useSelector} from "react-redux";

const CounterPage = ({tasbeehId}) => {
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);

    return (
        <Container>
            <Counter item={loadedTasbeeh} tasbeehId={tasbeehId}/>
        </Container>
    );
};

export default React.memo(CounterPage);

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
`;