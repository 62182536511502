import React, {useState} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CounterActionsDialog from "./CounterActionsDialog";
import ManualEntryDialog from "./ManualEntryDialog";
import {useDispatch, useSelector} from "react-redux";
import {saveCount} from "../../../../redux/actions/tasbeehs";
import {useNavigate} from "@reach/router";
import EntryForSomeoneDialog from "./EntryForSomeoneDialog";

const CountActionButton = ({onClear, onManualEntry, count, tasbeehId}) => {
    const [actionDialogVisible, setActionDialogVisible] = useState(false);
    const [manualEntryDialogVisible, setManualEntryDialogVisible] = useState(false);
    const [entryForSomeoneDialogVisible, setEntryForSomeoneDialogVisible] = useState(false);
    const userId = useSelector(state => state.localStorage.userId);
    const user = useSelector(state => state.localStorage.userName);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleChangeTab = () => {
        let location = window.location.href;
        if (location.endsWith('/2')){
            navigate(`${location.substring(0, location.length - 2)}/3`)
        }
        else{
            navigate(`${location}/3`)
        }
    };

    const onSubmit = () => {
        dispatch(saveCount(count, tasbeehId, user, userId));
        handleChangeTab();
    };

    const onEntryForSomeone = (values) => {
        if (values.name && values.count){
            dispatch(saveCount(values.count, tasbeehId, values.name, userId));
            handleChangeTab();
        }
    };

    return (
        <React.Fragment>
            <ActionButtonDiv onClick={()=>setActionDialogVisible(true)}>
                <MoreVertIcon/>
            </ActionButtonDiv>
            <SubmitButton
                disabled={!count || count < 1}
                variant={'outlined'} color={'primary'} onClick={()=>{onSubmit()}}>
                Submit
            </SubmitButton>
            <CounterActionsDialog
                open={actionDialogVisible}
                setDialog={setActionDialogVisible}
                onClear={onClear}
                onEntryForSomeone={()=>setEntryForSomeoneDialogVisible(true)}
                onManualEntry={()=>setManualEntryDialogVisible(true)}/>
            <ManualEntryDialog
                open={manualEntryDialogVisible}
                initialCount={count}
                onManualEntry={onManualEntry}
                onClose={()=>setManualEntryDialogVisible(false)}
            />
            <EntryForSomeoneDialog
                open={entryForSomeoneDialogVisible}
                onEntryForSomeone={onEntryForSomeone}
                onClose={()=>setEntryForSomeoneDialogVisible(false)}
            />
        </React.Fragment>

    );
};

export default CountActionButton;

const ActionButtonDiv = styled(IconButton)`
    &.MuiIconButton-root{
      position: absolute;
      left: 0;
      top: 0;
    }
`;

const SubmitButton = styled(Button)`
  &.MuiButton-outlined{
      margin: 5px;
      position: absolute;
      right: 0;
      top: 0;
    }
`;