import React, {useState} from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {completeJuz, reserveJuz} from "../../../../redux/actions/khatams";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import RenderJuz from "./RenderJuz";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import {toggleFavouriteKhatam} from "../../../../redux/actions/localStorage";
import ReserveButton from "./ReserveButton";
import CompleteOthersDialog from "./completeOthersDialog";

const RenderJuzLine = ({juz, quraanId, onAction}) => {
        const loadedQuraan = useSelector(state => state.khatams.quraan);
        const loading = useSelector(state => state.network.activeRequests);
        const user = useSelector(state => state.localStorage.userName);
        const userId = useSelector(state => state.localStorage.userId);
        const savedItems = useSelector(state => state.localStorage[`savedKhatams`]);
        const isFavourite = quraanId in savedItems;
        const [completeOthersActionOpen, setCompleteOthersActionOpen] = useState(false);
        const dispatch = useDispatch();
        const juzNumber = juz.number;
        const reservedByUser = loadedQuraan[juzNumber] && loadedQuraan[juzNumber].user_id;

        const onComplete = () => {
            if (reservedByUser)
                dispatch(completeJuz(quraanId, user, userId, juzNumber));
            else
                setCompleteOthersActionOpen(true)
        }

        const onReserve = (asUser = null) => {
            if (!isFavourite) {
                dispatch(toggleFavouriteKhatam(quraanId, loadedQuraan.description))
            }
            let userName = asUser ? asUser : user;
            dispatch(reserveJuz(true, quraanId, userName, userId, juzNumber))
        }

        const renderReservedByName = () => {
            return <Name>{loadedQuraan[juzNumber].name}</Name>
        };

        const renderActionButton = () => {
            const userStartedKhatam = loadedQuraan.created_by_id;

            if (!reservedByUser && !userStartedKhatam)
                return <div/>;

            return (
                <IconButton size={"small"} onClick={() => onAction()}>
                    <MoreVertIcon/>
                </IconButton>
            )
        };

        const renderDoneDiv = () => {
            if (reservedByUser)
                return (
                    <ActionIconButton disabled={!!loading} onClick={() => {
                        onComplete(juzNumber)
                    }}>
                        <DoneIcon/>
                        <RipppleSpan/>
                    </ActionIconButton>
                );
            return (
                <IconButton disabled={!!loading} onClick={() => {
                    onComplete(juzNumber)
                }}>
                    <DoneIcon/>
                </IconButton>
            );
        };

        const CompletedIcon = () => {
            return (
                <CompletedIconDiv>
                    <DoneAllIcon/>
                </CompletedIconDiv>
            )
        };

        const renderJuzQuickAction = () => {
            const juzCompleted = loadedQuraan[juzNumber].completed;

            if (juzCompleted) return CompletedIcon();
            return renderDoneDiv(juzNumber)
        };

        const renderReservedJuzActions = () => {
            if (!loadedQuraan[juzNumber]) return null;

            return (
                <NewJuzLayout>
                    {renderReservedByName()}
                    {renderJuzQuickAction()}
                    {renderActionButton()}
                </NewJuzLayout>
            )
        };

        const renderReserveButton = () => {
            const isReserved = juz.number in loadedQuraan;

            if (isReserved) return null;

            return (
                <JuzActions>
                    <ReserveButton
                        loading={loading}
                        onReserve={onReserve}/>
                </JuzActions>
            )
        };

        return (
            <ListItem key={juz.number} id={`juz-${juzNumber}`}>
                <RenderJuz juz={juz}/>
                {renderReserveButton()}
                {renderReservedJuzActions()}
                {
                    completeOthersActionOpen &&
                    <CompleteOthersDialog
                        setDialog={setCompleteOthersActionOpen}
                        quraanId={quraanId} juzNumber={juzNumber}/>
                }
            </ListItem>
        );
    }
;

export default React.memo(RenderJuzLine);

const ListItem = styled.div`
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: grid;
  grid-template-columns: 30px 135px auto;
  border-top: 1px solid #4a4949;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
  align-items: center;
`;

const JuzActions = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NewJuzLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 40px 40px;
  align-items: center;
`;

const ActionIconButton = styled(IconButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.MuiIconButton-root {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: #ffffff29;
  }
`;

const RipppleSpan = styled.span`
  @keyframes ripple {
    to {
      transform: scale(1);
      opacity: 0;
    }
  }
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 1000ms 2 linear;

`;

const Name = styled.p`
  text-align: center;
  padding-left: 5px;
  width: 90%;
  flex: 1.75;
  font-size: 0.8em;
`;

const CompletedIconDiv = styled.div`
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
`;