import {API_POST} from "./api";

export const LOAD_LOCAL_STORAGE = "LOAD_LOCAL_STORAGE";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_USER_ID = "SET_USER_ID";
export const SET_SAVED_KHATAMS = "SET_SAVED_KHATAMS";
export const SET_SAVED_TASBEEHS = "SET_SAVED_TASBEEHS";
export const SET_SAVED_GROUPS = "SET_SAVED_GROUPS";

export const SAVE_USER_NAME = "SAVE_USER_NAME";
export const TOGGLE_FAVOURITE_KHATAM = "TOGGLE_FAVOURITE_KHATAM";
export const TOGGLE_FAVOURITE_TASBEEH = "TOGGLE_FAVOURITE_TASBEEH";
export const TOGGLE_FAVOURITE_GROUP = "TOGGLE_FAVOURITE_GROUP";
export const UPDATE_SAVED_ITEM_DESCRIPTION = "UPDATE_SAVED_ITEM_DESCRIPTION";
export const ADD_FAVOURITE_KHATAM = "ADD_FAVOURITE_KHATAM";
export const REMOVE_FAVOURITE_KHATAM = "REMOVE_FAVOURITE_KHATAM";
export const ADD_FAVOURITE_TASBEEH = "ADD_FAVOURITE_TASBEEH";
export const REMOVE_FAVOURITE_TASBEEH = "REMOVE_FAVOURITE_TASBEEH";
export const ADD_FAVOURITE_GROUP = "ADD_FAVOURITE_GROUP";
export const REMOVE_FAVOURITE_GROUP = "REMOVE_FAVOURITE_GROUP";
export const UPDATE_UPDATED_AT_TIME = "UPDATE_UPDATED_AT_TIME";
export const REMOVE_FAVOURITE_SUCCESS = "REMOVE_FAVOURITE_SUCCESS";
export const REMOVE_FAVOURITE_FAILURE = "REMOVE_FAVOURITE_FAILURE";
export const SAVE_FAVOURITE_SUCCESS = "SAVE_FAVOURITE_SUCCESS";

export const loadLocalStorage = () => ({type: LOAD_LOCAL_STORAGE});
export const setUserName = (userName) => ({type: SET_USER_NAME, payload: userName});
export const setUserId = (userId) => ({type: SET_USER_ID, payload: userId});
export const setSavedKhatams = (savedKhatams) => ({type: SET_SAVED_KHATAMS, payload: savedKhatams});
export const setSavedTasbeehs = (savedTasbeehs) => ({type: SET_SAVED_TASBEEHS, payload: savedTasbeehs});
export const setSavedGroups = (savedGroups) => ({type: SET_SAVED_GROUPS, payload: savedGroups});
export const updateUpdatedAtTime = (type, itemId) => ({type: UPDATE_UPDATED_AT_TIME, payload: {type, itemId}})

export const saveUserName = (username) => ({type: SAVE_USER_NAME, payload: username});

export const toggleFavouriteKhatam = (quraanId, description) => ({
    type: TOGGLE_FAVOURITE_KHATAM,
    payload: {quraanId, description}
})

export const toggleFavouriteTasbeeh = (tasbeehId, description) => ({
    type: TOGGLE_FAVOURITE_TASBEEH,
    payload: {tasbeehId, description}
})

export const toggleFavouriteGroup = (groupId, description) => ({
    type: TOGGLE_FAVOURITE_GROUP,
    payload: {groupId, description}
})

export const removeFavouriteKhatam = (quraanId) => ({
    type: REMOVE_FAVOURITE_KHATAM,
    payload: {quraanId}
})

export const addFavouriteKhatam = (quraanId, description) => ({
    type: ADD_FAVOURITE_KHATAM,
    payload: {quraanId, description}
})

export const removeFavouriteTasbeeh = (tasbeehId) => ({
    type: REMOVE_FAVOURITE_TASBEEH,
    payload: {tasbeehId}
})

export const addFavouriteTasbeeh = (tasbeehId, description) => ({
    type: ADD_FAVOURITE_TASBEEH,
    payload: {tasbeehId, description}
})


export const removeFavouriteGroup = (groupId) => ({
    type: REMOVE_FAVOURITE_GROUP,
    payload: {groupId}
})

export const addFavouriteGroup = (groupId, description) => ({
    type: ADD_FAVOURITE_GROUP,
    payload: {groupId, description}
})

export const updateSavedItemDescription = (itemId, type, newDescription) => ({
    type: UPDATE_SAVED_ITEM_DESCRIPTION,
    payload: {itemId, type, newDescription}
});


const removeFavouriteSuccess = ({result, itemToRemove, itemId}) => ({
    type: REMOVE_FAVOURITE_SUCCESS,
    payload: {result, type: itemToRemove, id: itemId}
})

const onFailure = (payload) => ({
    type: REMOVE_FAVOURITE_FAILURE,
    payload
})

const onSaveFavouriteSuccess = (payload) => ({
    type: SAVE_FAVOURITE_SUCCESS,
    payload
})

export const removeFavourite = (userId, itemToRemove, itemId) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "remove_favourite",
            "type": "profile",
            "category": itemToRemove,
            "user_id": userId,
            "id": itemId
        },
        success: (result) => removeFavouriteSuccess({result, itemToRemove, itemId}),
        failure: (result) => onFailure({result, itemToRemove, itemId})
    }
})

export const addFavouriteToProfile = (userId, category, itemId, description, timeSaved) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "add_favourite",
            "type": "profile",
            "user_id": userId,
            "id": itemId,
            "modified_at": timeSaved,
            category,
            description
        },
        success: (result) => onSaveFavouriteSuccess({result, category, itemId}),
    }
})