import React from 'react';
import styled from "styled-components";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {restartKhatam} from "../../redux/actions/khatams";

const RestartButton = ({completed, item, type}) => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.localStorage.userId);

    const onClick = () => {
        if (type === 'khatam')
            dispatch(restartKhatam(item.id, userId))
    };

    if (!item.repeat)
        return null;

    if (!completed)
        return null;

    return (
        <Container>
            <p>
                {`The ${type} has been completed`}
            </p>
            <br/>
            <Button
                variant={"outlined"}
                color={"primary"}
                onClick={()=>{onClick()}}
            >
                {`Restart ${type}`}
            </Button>
        </Container>
    );
};

export default RestartButton;

const Container = styled.div`
  position: sticky;
  max-width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px 20px;
`;