import React from 'react';
import '../../App.css';
import {Fab} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import styled from "styled-components";
import {useNavigate} from "@reach/router"
import {useSelector} from "react-redux";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {getModifiedDateItem} from "../../utils/getModifiedDate";
import RegisterNotificationListItem from "../../components/registerNotificationListItem";

export const TasbeehList = () => {
    const savedTasbeehs = useSelector(state => state.localStorage.savedTasbeehs);

    const navigate = useNavigate();

    const navigateToNew = () => {
        navigate('/tasbeeh/new')
    };

    const renderSavedTasbeehItems = () => {
        let tasbeehList = Object.keys(savedTasbeehs).map((tasbeehId) => {
            let tasbeeh = savedTasbeehs[tasbeehId];
            tasbeeh['id'] = tasbeehId;

            if (!tasbeeh['modified']) {
                tasbeeh['modified'] = getModifiedDateItem(tasbeeh)['modified']
            }

            return tasbeeh
        })

        return tasbeehList.sort((a, b) => (new Date(b.modified) - new Date(a.modified)))
            .map(tasbeeh => {
                let tasbeehId = tasbeeh.id;
                return (
                    <ListItem key={tasbeehId} onClick={() => {
                        navigate(`/tasbeeh/${tasbeehId}`)
                    }}>
                        <SavedTasbeehListItem>
                            <SavedTasbeehDescription>
                                {savedTasbeehs[tasbeehId].description}
                            </SavedTasbeehDescription>
                            <SavedTasbeehAdded>
                                <SavedTasbeehDate>
                                    {savedTasbeehs[tasbeehId].modified ?
                                        new Date(savedTasbeehs[tasbeehId].modified).toLocaleDateString()
                                        :
                                        savedTasbeehs[tasbeehId].addedDate}
                                </SavedTasbeehDate>
                                <SavedTasbeehTime>
                                    {savedTasbeehs[tasbeehId].modified ?
                                        new Date(savedTasbeehs[tasbeehId].modified).toLocaleTimeString()
                                        :
                                        savedTasbeehs[tasbeehId].addedTime}
                                </SavedTasbeehTime>
                            </SavedTasbeehAdded>
                        </SavedTasbeehListItem>
                    </ListItem>
                );
            })
    };

    const renderSavedTasbeehs = () => {
        if (Object.keys(savedTasbeehs).length < 1)
            return (
                <TasbeehDiv>
                    <Title>Click on the Add Button to start a new Tasbeeh</Title>
                </TasbeehDiv>
            );

        return (
            <TasbeehDiv>
                <JuzDiv>
                    {renderSavedTasbeehItems()}
                    <LastChild/>
                </JuzDiv>
            </TasbeehDiv>
        );
    };

    return (
        <Container className="DarkMode">
            <NewTasbeehDiv>
                <Header title={'Your Saved Tasbeehs'}/>
                <RegisterNotificationListItem/>
                {renderSavedTasbeehs()}
                <Fab
                    onClick={() => navigateToNew()}
                    color="secondary" aria-label="add" style={{position: "absolute", bottom: "100px", right: "5%"}}>
                    <AddIcon/>
                </Fab>
                <Footer bottomBar/>
            </NewTasbeehDiv>
        </Container>
    );
}

export default TasbeehList;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const NewTasbeehDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;


const JuzDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
`;

const LastChild = styled.div`
  width: 100%;
  border-bottom: 1px solid #4a4949;
`;

const TasbeehDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: center;
`;

const ListItem = styled.div`
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #4a4949;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
`;


const SavedTasbeehListItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const SavedTasbeehDescription = styled.div`
  flex: 7;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  padding-left: 10px;
  min-width: 0;
`;

const SavedTasbeehAdded = styled.div`
  flex: 3;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SavedTasbeehDate = styled.div``;

const SavedTasbeehTime = styled.div``;