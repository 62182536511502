import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function SubmitAsDialog({open, onClose}) {
    const [name, setName] = useState("");

    const handleClose = (value) => {
        setName("");
        if (typeof value != "string")
            onClose(null)
        else
            onClose(value)
    }

    const onSubmit = () => {
        handleClose(name);
        setName("");
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Reserve Juz For</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={name}
                        label="Name"
                        type="name"
                        fullWidth
                        onChange={(e)=>setName(e.target.value)}
                    />
                    <p>
                        You will be also responsible for marking their Juz as complete</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={!name || name.length < 2} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}