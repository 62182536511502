import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "styled-components";
import ShowAppStoreBadge from "./Groups/components/showAppStoreBadge";

const Download = () => {

    return (
        <Container className="DarkMode">
            <Header title={"Download"}/>
            <ContentContainer>
                <p>The Quraan tracker App works best by downloading the App from your devices App store!</p>
                <ShowAppStoreBadge/>
            </ContentContainer>
            <Footer bottomBar/>
        </Container>
    );
};

export default Download;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
`;


const ContentContainer = styled.div`
    width: 90%;
    height: 90%;
    box-sizing:border-box;
    margin: 5% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`;
