import {API_POST} from "./api";

export const TASBEEH_SUCCESS = "TASBEEH_SUCCESS";
export const TASBEEH_FAILURE = "TASBEEH_FAILURE";
export const CLEAR_LOADED_TASBEEH = "CLEAR_LOADED_TASBEEH";
export const ADD_COUNT_SUCCESS = "ADD_COUNT_SUCCESS";
export const SET_LOADED_TASBEEH = "SET_LOADED_TASBEEH";
export const KHATAM_RESTART_SUCCESS = "KHATAM_RESTART_SUCCESS";
export const SET_TASBEEH_COUNT = "SET_TASBEEH_COUNT";
export const SAVE_TASBEEH_COUNT = "SAVE_TASBEEH_COUNT";
export const EDIT_TASBEEH_SUCCESS = "EDIT_TASBEEH_SUCCESS";
export const REMOVE_TASBEEH_SUCCESS = "REMOVE_TASBEEH_SUCCESS";
export const CHANGE_TASBEEH_INTENTION_SUCCESS = "CHANGE_TASBEEH_INTENTION_SUCCESS";
export const TASBEEH_OPENED = "TASBEEH_OPENED";
export const LOAD_TASBEEHS = "LOAD_TASBEEHS";
export const TASBEEH_REMOVED_SUCCESSFULLY = "TASBEEH_REMOVED_SUCCESSFULLY";
export const CHANGE_TASBEEH_TARGET_SUCCESS = "CHANGE_TASBEEH_TARGET_SUCCESS";

export const setLoadedTasbeeh = (tasbeeh) => ({type: SET_LOADED_TASBEEH, payload: tasbeeh});
export const clearLoadedTasbeeh = () => ({type: CLEAR_LOADED_TASBEEH});
export const setTasbeehCount = (tasbeehId, count) => ({type: SET_TASBEEH_COUNT, payload: {tasbeehId, count}});
export const saveTasbeehCount = (tasbeehId, count) => ({type: SAVE_TASBEEH_COUNT, payload: {tasbeehId, count}});
export const tasbeehOpened = (tasbeeh) => ({type: TASBEEH_OPENED, payload: tasbeeh})
export const loadTasbeehs = () => ({type: LOAD_TASBEEHS})

const onSuccess = (payload) => ({
    type: TASBEEH_SUCCESS,
    payload
})

const onAddCountSuccess = (payload) => ({
    type: ADD_COUNT_SUCCESS,
    payload
})

const onChangeTasbeehIntentionSuccess = (payload) => ({
    type: CHANGE_TASBEEH_INTENTION_SUCCESS,
    payload
})

export const onEditSuccess = (payload) => ({
    type: EDIT_TASBEEH_SUCCESS,
    payload
})

export const onChangeTargetSuccess = (payload) => ({
    type: CHANGE_TASBEEH_TARGET_SUCCESS,
    payload
})


export const onRemoveSuccess = (payload) => ({
    type: REMOVE_TASBEEH_SUCCESS,
    payload
})


const onFailure = (payload) => ({
    type: TASBEEH_FAILURE,
    payload
})

export const fetchTasbeeh = (tasbeehId, userId, shouldSubscribe) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "get",
            "type": "tasbeeh",
            "user_id": userId,
            "id": tasbeehId,
            'should_subscribe': shouldSubscribe
        },
        success: (result) => onSuccess({result, tasbeehId}),
        failure: (r) => onFailure(r)
    }
})

export const saveCount = (count, tasbeehId, user, userId) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "add_count",
            "type": "tasbeeh",
            "id": tasbeehId,
            "user": user,
            "user_id": userId,
            "count": count
        },
        success: (result) => onAddCountSuccess({result, tasbeehId}),
        failure: (r) => onFailure(r)
    }
})

export const editTasbeeh = (tasbeehId, itemId, user, userId) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "edit",
            "type": "tasbeeh",
            "id": tasbeehId,
            "user": user,
            "user_id": userId,
            "item_id": itemId
        },
        success: (result) => onEditSuccess({result, tasbeehId}),
        failure: (r) => onFailure(r)
    }
})

export const deleteTasbeeh = (tasbeehId, itemId, userId) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "remove",
            "type": "tasbeeh",
            "id": tasbeehId,
            "user_id": userId,
            "item_id": itemId
        },
        success: (result) => onRemoveSuccess({result, tasbeehId}),
        failure: (r) => onFailure(r)
    }
})

export const editTasbeehIntention = (userId, tasbeehId, intention) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "edit_intention",
            "type": "tasbeeh",
            "user_id": userId,
            "id": tasbeehId,
            "intention": intention
        },
        success: (result) => onChangeTasbeehIntentionSuccess({result, tasbeehId}),
        failure: (r) => onFailure(r)
    }
})


export const editTasbeehTarget = (userId, tasbeehId, target) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "edit_target",
            "type": "tasbeeh",
            "user_id": userId,
            "id": tasbeehId,
            target
        },
        success: (result) => onChangeTargetSuccess({result, tasbeehId}),
        failure: (r) => onFailure(r)
    }
})