import React from 'react';
import Button from "@material-ui/core/Button";

const CreateNewSubmitButton = ({disabled, onSubmit}) => {
    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={() => onSubmit()}
            disabled={disabled}
        >
            Next
        </Button>
    );
};

export default CreateNewSubmitButton;