import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from "styled-components";
import {useLocation, useNavigate} from "@reach/router";
import IcomoonReact from "icomoon-react";
import iconSet from "../assets/icomoon/selection.json";
import {PRIMARY_THEME_COLOR} from "../const";
import GroupIcon from '@material-ui/icons/Group';
import GetAppIcon from '@material-ui/icons/GetApp';

const options = [
    {
        path: "quraan",
        title: "Quraan",
        iconType: "icomoon",
        icon: "quraan"
    },
    {
        path: "download",
        title: "Download",
        iconType: "mui",
        icon: <GetAppIcon fontSize={"default"}/>
    },
    {
        path: "group",
        title: "Groups",
        iconType: "mui",
        icon: <GroupIcon fontSize={"default"}/>
    },
    {
        path: "tasbeeh",
        title: "Tasbeeh",
        iconType: "icomoon",
        icon: "tasbeeh"
    },
    {
        path: "settings",
        title: "Settings",
        iconType: "icomoon",
        icon: "settings"
    },
]


export default function BottomTabBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = React.useState(
        options.findIndex((option) => location.pathname.indexOf(`/${option.path}`) > -1)
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(`/${options[newValue].path}`, { replace: true })
    };

    return (
        <PaperContainer>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                textColor="secondary"
                aria-label="icon label tabs"
            >
                {options.map((option, index) => {
                    if (option.iconType === 'icomoon')
                        return (
                            <Tab
                                key={index}
                                icon={
                                    <IcomoonReact
                                        icon={option.icon}
                                        iconSet={iconSet}
                                        size={26}
                                        color={value === index ? PRIMARY_THEME_COLOR : "#666666"}
                                    />
                                }
                                label={option.title}/>
                        )
                    return (
                            <Tab
                                key={index}
                                icon={option.icon}
                                label={option.title}/>)
                })}
            </Tabs>
        </PaperContainer>
    );
}

const PaperContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #131313;
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  align-content: space-around;

  .MuiTabs-root {
    width: 100%;
  }

  .MuiTab-textColorSecondary {
    color: #666666;
  }

  .MuiTab-root {
    font-size: 10px;
    padding: 0 0 10px;
    text-transform: none;
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
