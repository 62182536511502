import React, {useEffect} from 'react';
import './App.css';
import RegisterUser from "./Views/RegisterUser";
import {ThemeProvider} from '@material-ui/core/styles';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {loadLocalStorage, saveUserName} from "../redux/actions/localStorage";
import useWindowSize from "./hooks/useWindowSize";
import Notistack from "./components/notistack";
import ApplicationRoutes from "./routes/ApplicationRoutes";
import ServiceWorkerWrapper from "./ServiceWorker";
import {createHistory, LocationProvider} from "@reach/router";
import analytics from "./analytics"
import {PRIMARY_THEME_COLOR} from "./const";
import {appLoaded} from "../redux/actions/pushNotifications";

const history = createHistory(window);

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {main: PRIMARY_THEME_COLOR},
        secondary: {main: PRIMARY_THEME_COLOR}
    },
});

function App() {
    const userName = useSelector(state => state.localStorage.userName)
    const dispatch = useDispatch();
    const {height} = useWindowSize();
    const userId = useSelector(state => state.localStorage.userId);

    history.listen(window => {
        analytics.sendPageview(window.location.pathname + window.location.search);
    });

    useEffect(() => {
        dispatch(loadLocalStorage());
        dispatch(appLoaded())
        analytics.init();
    }, [dispatch])

    useEffect(() => {
        if (!userId) return;

        analytics.setProperty('userId', userId)
    }, [userId])

    const onRegisterUser = (user) => {
        dispatch(saveUserName(user))
    };

    const renderApp = () => {
        if (userName || (window.location.pathname.replace(/\//g, "") === "privacy"))
            return <ApplicationRoutes/>

        return <RegisterUser onSetUser={onRegisterUser}/>
    }
    return (
        <Container style={{height: height, minHeight: height, maxHeight: height}}>
            <LocationProvider history={history}>
                <ThemeProvider theme={darkTheme}>
                    <ServiceWorkerWrapper/>
                    <Notistack/>
                    {renderApp()}
                </ThemeProvider>
            </LocationProvider>
        </Container>

    );
}

export default App;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`;