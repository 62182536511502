import React from 'react';
import FallbackErrorBoundary from "../../FallbackErrorBoundary";
import QuraanList from "./QuraanList";
import LegacyQuraanList from "../../LegacyViews/Quraan/QuraanList";

const QuraanListComponent = () => {
    return (
        <FallbackErrorBoundary
            component={LegacyQuraanList}
        >
            <QuraanList/>
        </FallbackErrorBoundary>
    );
};

export default QuraanListComponent;