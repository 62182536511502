import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch} from "react-redux";
import {setSnackbarWarning} from "../../../../redux/actions/notistack";

export default function ManualEntryDialog({open, onClose, onManualEntry, initialCount}) {
    const [count, setCount] = useState(initialCount || 0);
    const dispatch = useDispatch();

    useEffect(()=>{
        setCount(initialCount);
    },[initialCount])

    const handleClose = () => {
        onClose()
    }

    const isCountValid = () => {
        return /^\d+$/.test(count);
    }

    const onSubmit = () => {
        if (isCountValid()) {
            onManualEntry(count);
            handleClose();
        } else {
            dispatch(setSnackbarWarning("Please enter a valid count"));
        }

    };

    const handleFocus = (event) => event.target.select();

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Manual Entry</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        onFocus={handleFocus}
                        margin="dense"
                        id="count"
                        value={count}
                        label="Count"
                        type="number"
                        fullWidth
                        onChange={(e) => setCount(e.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={!count || !isCountValid} color="primary">
                        Set Count
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}