import {
    ADD_FAVOURITE_GROUP,
    ADD_FAVOURITE_KHATAM,
    ADD_FAVOURITE_TASBEEH,
    addFavouriteGroup, addFavouriteToProfile,
    LOAD_LOCAL_STORAGE, REMOVE_FAVOURITE_FAILURE,
    REMOVE_FAVOURITE_GROUP,
    REMOVE_FAVOURITE_KHATAM, REMOVE_FAVOURITE_SUCCESS,
    REMOVE_FAVOURITE_TASBEEH, removeFavourite,
    removeFavouriteGroup, removeFavouriteKhatam, removeFavouriteTasbeeh,
    SAVE_USER_NAME,
    setSavedGroups,
    setSavedKhatams,
    setSavedTasbeehs,
    setUserId,
    setUserName,
    TOGGLE_FAVOURITE_GROUP,
    TOGGLE_FAVOURITE_KHATAM,
    TOGGLE_FAVOURITE_TASBEEH,
    UPDATE_SAVED_ITEM_DESCRIPTION,
    UPDATE_UPDATED_AT_TIME
} from "../actions/localStorage";
import uuidv4 from "../../uuidv4";
import {SAVE_TASBEEH_COUNT} from "../actions/tasbeehs";
import {enqueueSnackbar} from "../actions/notistack";

export default function localStorageMiddleware({getState, dispatch}) {
    return next => action => {
        next(action)

        const fetchUserName = () => {
            let userName = localStorage.getItem("userName")
            dispatch(setUserName(userName));
        };

        const fetchUserId = () => {
            let userId = localStorage.getItem("userId")
            if (!userId) {
                userId = uuidv4();
                localStorage.setItem("userId", userId);
            }
            dispatch(setUserId(userId));
        };

        const saveItems = (itemsToSave, category) => {
            if (!itemsToSave) return;
            const itemsToSaveJson = typeof itemsToSave === "string" ? JSON.parse(itemsToSave) : itemsToSave;

            let userId = localStorage.getItem("userId");
            Object.keys(itemsToSaveJson).forEach((key)=> {
                const item = itemsToSaveJson[key]
                dispatch(addFavouriteToProfile(userId, category, key, item['description'], item['modified']))
            })
        }

        const initializeCloudSync = () => {
            let cloudSyncInitialized = localStorage.getItem("cloudSyncInitializedV0")
            if (!!cloudSyncInitialized) return;

            let savedKhatams = localStorage.getItem("savedKhatams")
            saveItems(savedKhatams, 'quraan')

            let savedTasbeehs = localStorage.getItem("savedTasbeehs")
            saveItems(savedTasbeehs, 'tasbeeh')

            let savedGroups = localStorage.getItem("savedGroups");
            saveItems(savedGroups, 'group')

            localStorage.removeItem("cloudSyncInitialized")
            localStorage.setItem("cloudSyncInitializedV0", "true");
        }

        const fetchSavedKhatams = () => {
            let savedKhatams = localStorage.getItem("savedKhatams")
            dispatch(setSavedKhatams(JSON.parse(savedKhatams || "{}")));
        };


        const fetchSavedTasbeehs = () => {
            let savedTasbeehs = localStorage.getItem("savedTasbeehs")
            dispatch(setSavedTasbeehs(JSON.parse(savedTasbeehs || "{}")));
        };

        const fetchSavedGroups = () => {
            let savedGroups = localStorage.getItem("savedGroups");
            dispatch(setSavedGroups(JSON.parse(savedGroups || "{}")));
        };

        const fetchItems = (type) => {
            if (type === 'Khatam') {
                fetchSavedKhatams()
            } else if (type === 'Tasbeeh') {
                fetchSavedTasbeehs()
            } else if (type === 'Group') {
                fetchSavedGroups()
            }
        };

        const setSnackbarError = (message) => {
            dispatch(enqueueSnackbar({
                message,
                options: {
                    variant: 'error',
                    autoHideDuration: 3000
                }
            }));
        };

        if (action.type === LOAD_LOCAL_STORAGE) {
            fetchUserName();
            fetchUserId();
            fetchSavedKhatams();
            fetchSavedTasbeehs();
            fetchSavedGroups();
            initializeCloudSync();
        }

        if (action.type === SAVE_USER_NAME) {
            if (action.payload && action.payload.length > 3) {
                localStorage.setItem('userName', action.payload);
                dispatch(setUserName(action.payload))
            }
        }

        if (action.type === UPDATE_SAVED_ITEM_DESCRIPTION) {
            const {type, itemId, newDescription} = action.payload;
            let localItemsName = `saved${type}s`;
            let savedItems = localStorage.getItem(localItemsName)
            let parsedSavedItems = JSON.parse(savedItems || {});
            if (itemId in parsedSavedItems) {
                parsedSavedItems[itemId]['description'] = newDescription;
                localStorage.setItem(localItemsName, JSON.stringify(parsedSavedItems));
                fetchItems(type);
            }
        }

        if (action.type === UPDATE_UPDATED_AT_TIME) {
            const {type, itemId} = action.payload;
            let localItemsName = `saved${type}s`;
            let savedItems = localStorage.getItem(localItemsName)
            let parsedSavedItems = JSON.parse(savedItems || {});
            if (itemId in parsedSavedItems) {
                parsedSavedItems[itemId]['modified'] = new Date().toISOString();
                localStorage.setItem(localItemsName, JSON.stringify(parsedSavedItems));
                fetchItems(type);
            }
        }

        if (action.type === REMOVE_FAVOURITE_KHATAM) {
            let quraanId = action.payload.quraanId;
            let savedKhatams = getState().localStorage.savedKhatams;
            if (quraanId in savedKhatams) {
                let {[quraanId]: value, ...remainder} = savedKhatams;
                localStorage.setItem('savedKhatams', JSON.stringify(remainder));
                dispatch(setSavedKhatams(remainder))
            }
        }

        if (action.type === ADD_FAVOURITE_KHATAM) {
            let quraanId = action.payload.quraanId;
            let quraanDescription = action.payload.description;
            let savedKhatams = getState().localStorage.savedKhatams;
            let currentDate = new Date();
            savedKhatams[quraanId] = {
                description: quraanDescription,
                modified: currentDate.toISOString()
            }
            localStorage.setItem('savedKhatams', JSON.stringify(savedKhatams));
            dispatch(setSavedKhatams(savedKhatams))
        }

        if (action.type === REMOVE_FAVOURITE_TASBEEH) {
            let tasbeehId = action.payload.tasbeehId;
            let savedTasbeehs = getState().localStorage.savedTasbeehs;

            if (tasbeehId in savedTasbeehs) {
                let {[tasbeehId]: value, ...remainder} = savedTasbeehs;
                localStorage.setItem('savedTasbeehs', JSON.stringify(remainder));
                dispatch(setSavedTasbeehs(remainder))
            }
        }

        if (action.type === ADD_FAVOURITE_TASBEEH) {
            let tasbeehId = action.payload.tasbeehId;
            let tasbeehDescription = action.payload.description;
            let savedTasbeehs = getState().localStorage.savedTasbeehs;
            let currentDate = new Date();
            savedTasbeehs[tasbeehId] = {
                description: tasbeehDescription,
                modified: currentDate.toISOString()
            }
            localStorage.setItem('savedTasbeehs', JSON.stringify(savedTasbeehs));
            dispatch(setSavedTasbeehs(savedTasbeehs))
        }


        if (action.type === SAVE_TASBEEH_COUNT) {
            let {tasbeehId, count} = action.payload;
            let savedTasbeehs = getState().localStorage.savedTasbeehs;
            if (tasbeehId in savedTasbeehs) {
                let {[tasbeehId]: value, ...remainder} = savedTasbeehs;
                let tasbeeh = {...value, count}
                let saved = {...remainder, [tasbeehId]: tasbeeh};
                localStorage.removeItem('savedTasbeehs');
                localStorage.setItem('savedTasbeehs', JSON.stringify(saved));
                dispatch(setSavedTasbeehs(saved))
            }
        }

        if (action.type === REMOVE_FAVOURITE_GROUP) {
            let groupId = action.payload.groupId;
            let savedGroups = getState().localStorage.savedGroups;

            if (groupId in savedGroups) {
                let {[groupId]: value, ...remainder} = savedGroups;
                localStorage.setItem('savedGroups', JSON.stringify(remainder));
                dispatch(setSavedGroups(remainder))
            }
        }

        if (action.type === ADD_FAVOURITE_GROUP) {
            let groupId = action.payload.groupId;
            let groupDescription = action.payload.description;
            let savedGroups = getState().localStorage.savedGroups;
            let currentDate = new Date();
            savedGroups[groupId] = {
                description: groupDescription,
                modified: currentDate.toISOString()
            }
            localStorage.setItem('savedGroups', JSON.stringify(savedGroups));
            dispatch(setSavedGroups(savedGroups))
        }

        if (action.type === REMOVE_FAVOURITE_SUCCESS) {
            const {type, id} = action.payload;
            if (type === "quraan") dispatch(removeFavouriteKhatam(id))
            else if (type === 'tasbeeh') dispatch(removeFavouriteTasbeeh(id))
            else if (type === 'group') dispatch(removeFavouriteGroup(id))
        }

        if (action.type === REMOVE_FAVOURITE_FAILURE) {
            setSnackbarError("Failed to remove, please try again later")
        }

        if (action.type === TOGGLE_FAVOURITE_KHATAM) {
            let quraanId = action.payload.quraanId;
            let quraanDescription = action.payload.description;
            let savedKhatams = getState().localStorage.savedKhatams;
            if (quraanId in savedKhatams) {
                dispatch(removeFavourite(getState()?.localStorage?.userId, 'quraan', quraanId))
            } else {
                let currentDate = new Date();
                savedKhatams[quraanId] = {
                    description: quraanDescription,
                    addedDate: currentDate.toLocaleDateString(),
                    addedTime: currentDate.toLocaleTimeString()
                }
                localStorage.setItem('savedKhatams', JSON.stringify(savedKhatams));
                dispatch(setSavedKhatams(savedKhatams))
            }
        }

        if (action.type === TOGGLE_FAVOURITE_TASBEEH) {
            let tasbeehId = action.payload.tasbeehId;
            let tasbeehDescription = action.payload.description;
            let savedTasbeehs = getState().localStorage.savedTasbeehs;
            if (tasbeehId in savedTasbeehs) {
                dispatch(removeFavourite(getState()?.localStorage?.userId, 'tasbeeh', tasbeehId))
            } else {
                let currentDate = new Date();
                savedTasbeehs[tasbeehId] = {
                    description: tasbeehDescription,
                    addedDate: currentDate.toLocaleDateString(),
                    addedTime: currentDate.toLocaleTimeString()
                }
                localStorage.setItem('savedTasbeehs', JSON.stringify(savedTasbeehs));
                dispatch(setSavedTasbeehs(savedTasbeehs))
            }
        }

        if (action.type === TOGGLE_FAVOURITE_GROUP) {
            let groupId = action.payload.groupId;
            let groupDescription = action.payload.description;
            let savedGroups = getState().localStorage.savedGroups;
            if (groupId in savedGroups) {
                dispatch(removeFavourite(getState()?.localStorage?.userId, 'group', groupId))
            } else {
                dispatch(addFavouriteGroup(groupId, groupDescription))
            }
        }
    }
}