import {NETWORK_ENDED, NETWORK_STARTED} from "../actions/api";

const DEFAULT_STATE = {
    activeRequests: 0
};

export default function networkReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case NETWORK_STARTED: {
            return {...state, activeRequests: state.activeRequests + 1}
        }
        case NETWORK_ENDED: {
            return {...state, activeRequests: state.activeRequests - 1 }
        }
        default:
            return {...state}
    }
}