import React from 'react';
import TasbeehToRead from "../TasbeehToRead";
import styled from "styled-components";
import {useSelector} from "react-redux";
import TargetBox from "./TargetBox";
import Intention from "../../../components/Intention";
import EditTargetDialog from "./EditTargetDialog";

const TasbeehPage = () => {
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);
    const remainingCount = parseInt(loadedTasbeeh.target || 0) - parseInt(loadedTasbeeh.currentCount || 0);
    const countToRender = Math.max(0, remainingCount);

    return (
        <Container>
            <TargetDiv>
                <TargetBox title={"Target"} value={loadedTasbeeh.target}/>
                <TargetBox title={"Remainder"} value={countToRender} large/>
                <TargetBox title={"Complete"} value={loadedTasbeeh.currentCount || 0}/>
                <EditTargetDialog item={loadedTasbeeh}/>
            </TargetDiv>
            <Intention item={loadedTasbeeh} type={"Tasbeeh"}/>
            <TasbeehToRead item={loadedTasbeeh}/>
        </Container>
    );
};

export default React.memo(TasbeehPage);

const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`;

const TargetDiv = styled.div`
  box-sizing: border-box;
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  color: white;
  text-align: center;
  flex-wrap: wrap;
`;
