import React, {useState} from 'react';
import styled from "styled-components";
import {API_URL} from "../../const";
import {useNavigate} from "@reach/router";
import {useDispatch, useSelector} from "react-redux";
import {toggleFavouriteKhatam} from "../../../redux/actions/localStorage";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import RepeatSwitch from "../../components/RepeatSwitch";
import IntentionCapture from "../../components/IntentionCapture";
import CreateNewSubmitButton from "../../components/CreateNewSubmitButton";
import {setSnackbarError} from "../../../redux/actions/notistack";

const NewQuraan = () => {
    const [intention, setIntention] = useState("");
    const [repeat, setRepeat] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector(state => state.localStorage.userId);
    const userName = useSelector(state => state.localStorage.userName);
    const loading = useSelector(state => state.network.activeRequests);

    const onSubmit = () => {
        let payload = {
            "action": "create",
            "type": "quraan",
            "description": intention,
            "user_id": userId,
            "user": userName,
            "repeat": repeat
        }
        fetch(API_URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then((r) => r.json())
            .then((result) => {
                if (result.new_quraan_id) {
                    dispatch(toggleFavouriteKhatam(result.new_quraan_id, intention))
                    navigate(`/quraan/${result.new_quraan_id}`)
                } else {
                    dispatch(setSnackbarError("Failed to create Khatam"));
                }
            })
    }

    const disableSubmit = () => {
        return !intention || intention.length < 4 || loading > 0;
    };

    return (
        <Container className="DarkMode">
            <Header title={"New Khatam"} back={"/quraan"}/>
            <ContentContainer>
                <IntentionCapture intention={intention} setIntention={setIntention} type={"Khatam"}/>
                <RepeatSwitch repeat={repeat} setRepeat={setRepeat} type={'khatam'}/>
                <br/>
                <CreateNewSubmitButton disabled={disableSubmit()} onSubmit={onSubmit}/>
            </ContentContainer>
            <Footer/>
        </Container>
    );
};

export default NewQuraan;

const Container = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;
