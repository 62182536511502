import {API_POST} from "./api";
export const GROUPS_LOADED_SUCCESS = "GROUPS_LOADED_SUCCESS";
export const GROUP_FAILURE = "GROUP_FAILURE";
export const JUZ_COMPLETE_SUCCESS = "JUZ_COMPLETE_SUCCESS";
export const JUZ_EDIT_SUCCESS = "JUZ_EDIT_SUCCESS";
export const JUZ_RESERVE_SUCCESS = "JUZ_RESERVE_SUCCESS";
export const SET_LOADED_GROUP = "SET_LOADED_GROUP";
export const GROUP_RESTART_SUCCESS = "GROUP_RESTART_SUCCESS";
export const CHANGE_GROUP_INTENTION_SUCCESS = "CHANGE_GROUP_INTENTION_SUCCESS";
export const GROUP_OPENED = "GROUP_OPENED";
export const LOAD_GROUPS = "LOAD_GROUPS";
export const GROUP_REMOVED_SUCCESSFULLY = "GROUP_REMOVED_SUCCESSFULLY";
export const CLEAR_LOADED_GROUP = "CLEAR_LOADED_GROUP";

export const setLoadedGroup = (group) => ({type: SET_LOADED_GROUP, payload: group});
export const groupOpened = (group) => ({type: GROUP_OPENED, payload: group})


export const loadGroups = () => ({type: LOAD_GROUPS})
const onSuccess = (payload) => ({
    type: GROUPS_LOADED_SUCCESS,
    payload
})

export const clearLoadedGroup = () => ({
    type: CLEAR_LOADED_GROUP
})
const onRemoveGroupSuccess = (payload) => ({
    type: GROUP_REMOVED_SUCCESSFULLY,
    payload
})

const onFailure = (payload) => ({
    type: GROUP_FAILURE,
    payload
})

export const fetchGroup = (groupId, userId, shouldSubscribe) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "get",
            "type": "group",
            "user_id":userId,
            "id": groupId,
            'should_subscribe': shouldSubscribe
        },
        success: (result) => onSuccess({result, groupId}),
        failure: (r) => onFailure(r)
    }
})

export const removeGroup = (userId, groupId) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "remove_group",
            "type": "group",
            "user_id":userId,
            "id": groupId,
        },
        success: (result) => onRemoveGroupSuccess({result, groupId}),
        failure: (r) => onFailure(r)
    }
})