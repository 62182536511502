import {
    CHANGE_QURAAN_INTENTION_SUCCESS,
    JUZ_COMPLETE_SUCCESS, JUZ_EDIT_SUCCESS,
    JUZ_RESERVE_SUCCESS,
    KHATAM_FAILURE, KHATAM_OPENED, KHATAM_RESTART_SUCCESS,
    KHATAM_SUCCESS,
    setLoadedQuraan
} from "../actions/khatams";
import {enqueueSnackbar} from "../actions/notistack";
import {addFavouriteKhatam, loadLocalStorage, toggleFavouriteKhatam} from "../actions/localStorage";

export default function khatamMiddleware({getState, dispatch}) {
    return next => action => {
        next(action)

        const setSnackbarSuccess = (message) => {
            dispatch(enqueueSnackbar({
                message,
                options: {
                    variant: 'success',
                    autoHideDuration: 3000
                }
            }));
        };
        const setSnackbarError = (message) => {
            dispatch(enqueueSnackbar({
                message,
                options: {
                    variant: 'error',
                    autoHideDuration: 3000
                }
            }));
        };

        const onJuzCompleted = (result, juzNumber) => {
            if (result.success) {
                setSnackbarSuccess(`Completed Juz ${parseInt(juzNumber)}`)
            } else {
                setSnackbarError(`Failed to mark Juz ${parseInt(juzNumber)} complete`)
            }
        };

        const onJuzEdited = (result, juzNumber) => {
            if (result.success) {
                setSnackbarSuccess(`Edited Juz ${parseInt(juzNumber)}`)
            } else {
                setSnackbarError(`Failed to edit Juz ${parseInt(juzNumber)}`)
            }
        };

        const onReserveResult = (result, reserve, juzNumber) => {
            if (result.success) {
                if (reserve)
                    setSnackbarSuccess(`Reserved Juz ${parseInt(juzNumber)}`)
                else
                    setSnackbarSuccess(`Unreserved Juz ${parseInt(juzNumber)}`)

            } else {
                if (reserve)
                    setSnackbarError(`Could not reserve Juz ${parseInt(juzNumber)}`)
                else
                    setSnackbarError(`Could not unreserve Juz ${parseInt(juzNumber)}`)
            }
        };

        const onKhatamRestarted = (result) => {
            if (result.success) {
                setSnackbarSuccess(`Restarted Khatam`)
            } else {
                setSnackbarError(`Failed to restart khatam`)
            }
        };

        const onIntentionChanged = (result) => {
            if (result.success) {
                setSnackbarSuccess(`Successfully Updated Intention!`)
            } else {
                setSnackbarError(`Failed to update intention!`)
            }
        };

        const handleQuraanIdInSavedKhatams = (quraanId) => {
            let savedKhatams = getState().localStorage.savedKhatams;
            if (quraanId in savedKhatams) {
                dispatch(toggleFavouriteKhatam(quraanId, "Not Found"))
            }
        };

        const addKhatamToSavedItems = (khatam) => {
            let savedKhatams = getState().localStorage.savedKhatams;
            if (!(khatam.id in savedKhatams)){
                dispatch(addFavouriteKhatam(khatam.id, khatam.description))
            }
        };

        const handleNotFoundQuraan = (quraanId) => {
            handleQuraanIdInSavedKhatams(quraanId);
            dispatch(loadLocalStorage());
            dispatch(setLoadedQuraan({NOT_FOUND: true}))
        };

        if (action.type === KHATAM_SUCCESS) {
            let {result, quraanId} = action.payload;
            if (result === 'Not Found!') {
                handleNotFoundQuraan(quraanId)
            }

            if (result?.quraan)
                return dispatch(setLoadedQuraan(result.quraan))
            return;
        }

        if (action.type === KHATAM_FAILURE) {
            setSnackbarError("Failed to perform action, please try again")
        }

        if (action.type === JUZ_RESERVE_SUCCESS) {
            let {result, juzNumber, reserve} = action.payload;
            if (result.quraan)
                dispatch(setLoadedQuraan(result.quraan))
            return onReserveResult(result, reserve, juzNumber)
        }

        if (action.type === JUZ_COMPLETE_SUCCESS) {
            let {result, juzNumber} = action.payload;
            if (result.quraan)
                dispatch(setLoadedQuraan(result.quraan))
            return onJuzCompleted(result, juzNumber)
        }

        if (action.type === JUZ_EDIT_SUCCESS) {
            let {result, juzNumber} = action.payload;
            if (result.quraan)
                dispatch(setLoadedQuraan(result.quraan))
            return onJuzEdited(result, juzNumber)
        }

        if (action.type === KHATAM_RESTART_SUCCESS) {
            let {result} = action.payload;
            if (result.quraan)
                dispatch(setLoadedQuraan(result.quraan))
            return onKhatamRestarted(result)
        }

        if (action.type === CHANGE_QURAAN_INTENTION_SUCCESS) {
            let {result} = action.payload;
            if (result.quraan)
                dispatch(setLoadedQuraan(result.quraan))
            return onIntentionChanged(result)
        }

        if (action.type === KHATAM_OPENED) {
            if (action.payload.NOT_FOUND) return;
            addKhatamToSavedItems(action.payload);
        }
    }
}