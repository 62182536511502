import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearLoadedGroup, fetchGroup, groupOpened} from "../../../redux/actions/groups";
import Intention from "../../components/Intention";
import Loading from "../../components/Loading";
import GroupListView from "./components/GroupListView";
import styled from "styled-components";
import Header from "../../components/Header";
import ShareButton from "../../components/ShareButton";
import FavouriteButton from "../../components/FavouriteButton";
import {toggleFavouriteGroup} from "../../../redux/actions/localStorage";
import {isCompleted} from "../Quraan/quraanUtils";

export const Group = (props) => {
    const groupId = props.groupId;
    const savedGroups = useSelector(state => state?.localStorage?.savedGroups);
    const loadedGroup = useSelector(state => state.khatams.group);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.localStorage.userId);

    useEffect(() => {
        dispatch(fetchGroup(groupId, userId, !savedGroups[groupId]));
        return (() => {
            dispatch(clearLoadedGroup())
        })
    }, [dispatch, groupId, userId]);

    useEffect(() => {
        if (!loadedGroup) return;
        dispatch(groupOpened(loadedGroup));
    }, [dispatch, loadedGroup])


    return (
        <Loading {...props} item={loadedGroup} type={"Group"}>
            <Container className="DarkMode">
                <Header title={'Group'} back={"/group"}>
                    <ShareButton item={loadedGroup} type={'Group'}/>
                    <FavouriteButton
                        item={loadedGroup}
                        toggle={toggleFavouriteGroup}
                        type={'Group'}
                        complete={isCompleted(loadedGroup)}
                    />
                </Header>
                <Intention
                    prefix={"Group Name: "}
                    item={loadedGroup} type={"Group"}/>
                <GroupListView {...props}/>
            </Container>
        </Loading>

    );
}

export default Group;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;



