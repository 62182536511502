import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {Dialog, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {registerPushNotification} from "../../redux/actions/pushNotifications";

const RegisterNotificationListItem = () => {
    const [open, setOpen] = useState(false);
    const showNotification = useSelector(state => state.pushNotifications.showNotification)
    const dispatch = useDispatch();

    const handleClose = (response) => {
        setOpen(false);
        if (response === "OK")
            dispatch(registerPushNotification())
    }

    if (!showNotification) return null;

    return (
        <ListItem>
            Enable notifications
            <Button
                variant={"contained"}
                color={"secondary"}
                style={{margin: "auto 10px auto auto"}}
                onClick={() => setOpen(true)}>
                Enable
            </Button>
            <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}>
                <DialogTitle id="simple-dialog-title">Enable Notifications</DialogTitle>
                <DialogContent>
                    Please click "Allow" on the popup that appears to enable notifications
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleClose("OK")} color="primary">
                    OK
                </Button>
            </DialogActions>
            </Dialog>
        </ListItem>
    );
};

export default RegisterNotificationListItem;

const ListItem = styled.div`
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #4a4949;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
  padding-left: 10px;
  align-items: center;
`