import {
    TASBEEH_FAILURE,
    TASBEEH_SUCCESS,
    setLoadedTasbeeh,
    SET_TASBEEH_COUNT,
    saveTasbeehCount,
    ADD_COUNT_SUCCESS,
    setTasbeehCount,
    EDIT_TASBEEH_SUCCESS,
    REMOVE_TASBEEH_SUCCESS, CHANGE_TASBEEH_INTENTION_SUCCESS, TASBEEH_OPENED, CHANGE_TASBEEH_TARGET_SUCCESS
} from "../actions/tasbeehs";
import {enqueueSnackbar} from "../actions/notistack";
import {addFavouriteTasbeeh, toggleFavouriteTasbeeh} from "../actions/localStorage";

export default function tasbeehMiddleware({getState, dispatch}) {
    return next => action => {
        next(action)

        const setSnackbarSuccess = (message) => {
            dispatch(enqueueSnackbar({
                message,
                options: {
                    variant: 'success',
                    autoHideDuration: 3000
                }
            }));
        };
        const setSnackbarError = (message) => {
            dispatch(enqueueSnackbar({
                message,
                options: {
                    variant: 'error',
                    autoHideDuration: 3000
                }
            }));
        };

        const addTasbeehToSavedItems = (tasbeeh) => {
            let savedTasbeehs = getState().localStorage.savedTasbeehs;
            if (!(tasbeeh.id in savedTasbeehs)) {
                dispatch(addFavouriteTasbeeh(tasbeeh.id, tasbeeh.description))
            }
        };

        if (action.type === TASBEEH_SUCCESS) {
            let {result, tasbeehId} = action.payload;
            let {tasbeeh} = result;
            if (result === "Not Found!"){
                let savedTasbeehs = getState().localStorage.savedTasbeehs
                if (tasbeehId in savedTasbeehs){
                    dispatch(toggleFavouriteTasbeeh(tasbeehId, 'Remove'))
                }
                return dispatch(setLoadedTasbeeh({NOT_FOUND:true}))

            }
            return dispatch(setLoadedTasbeeh(tasbeeh))
        }

        if (action.type === TASBEEH_FAILURE) {
            setSnackbarError("Failed to perform action, please try again!")
        }

        if (action.type === SET_TASBEEH_COUNT) {
            let savedTasbeehs = getState().localStorage.savedTasbeehs
            const {count, tasbeehId} = action.payload;
            if (!(tasbeehId in savedTasbeehs)){
                let loadedTasbeeh = getState().khatams.tasbeeh
                if (loadedTasbeeh.id === tasbeehId){
                    dispatch(toggleFavouriteTasbeeh(loadedTasbeeh.id, loadedTasbeeh.description))
                }
            }
            dispatch(saveTasbeehCount(tasbeehId, count))
        }

        if (action.type === ADD_COUNT_SUCCESS){
            let {result, tasbeehId} = action.payload;
            let {tasbeeh, success} = result;
            if (success){
                setSnackbarSuccess("Successfully added your count!")
                dispatch(setTasbeehCount(tasbeehId, 0));
            }
            else {
                setSnackbarError("Failed to save your count!")
            }
            return dispatch(setLoadedTasbeeh(tasbeeh))
        }

        if (action.type === EDIT_TASBEEH_SUCCESS){
            let {result} = action.payload;
            let {tasbeeh, success} = result;
            if (success){
                setSnackbarSuccess("Successfully Edited Name!")
            }
            else {
                setSnackbarError("Failed to edit name!")
            }
            return dispatch(setLoadedTasbeeh(tasbeeh))
        }

        if (action.type === REMOVE_TASBEEH_SUCCESS){
            let {result} = action.payload;
            let {tasbeeh, success} = result;
            if (success){
                setSnackbarSuccess("Successfully Removed Entry!")
            }
            else {
                setSnackbarError("Failed to remove entry!")
            }
            return dispatch(setLoadedTasbeeh(tasbeeh))
        }

        if (action.type === CHANGE_TASBEEH_INTENTION_SUCCESS){
            let {result} = action.payload;
            let {tasbeeh, success} = result;
            if (success){
                setSnackbarSuccess("Successfully Updated Intention!")
            }
            else {
                setSnackbarError("Failed to update intention!")
            }
            return dispatch(setLoadedTasbeeh(tasbeeh))
        }

        if (action.type === CHANGE_TASBEEH_TARGET_SUCCESS){
            let {result} = action.payload;
            let {tasbeeh, success} = result;
            if (success){
                setSnackbarSuccess("Successfully Updated Target!")
            }
            else {
                setSnackbarError("Failed to update target!")
            }
            return dispatch(setLoadedTasbeeh(tasbeeh))
        }

        if (action.type === TASBEEH_OPENED) {
            if (action.payload.NOT_FOUND) return;
            addTasbeehToSavedItems(action.payload);
        }
    }
}