import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

function CounterActionsDialog({open, setDialog, onClear, onManualEntry, onEntryForSomeone}) {

    let actions;

    actions = [
        "Enter Manually",
        "Enter for someone else",
        "Clear Counter"
    ]

    const handleClose = () => {
        setDialog(false);
    }

    const handleListItemClick = (value) => {
        if (value === "Enter Manually") {
            onManualEntry();
            handleClose();
        }
        if (value === "Enter for someone else") {
            onEntryForSomeone();
            handleClose();
        }
        if (value === "Clear Counter") {
            onClear();
            handleClose();
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Actions</DialogTitle>
            <List>
                {actions.map((action) => (
                    <ListItem onClick={() => handleListItemClick(action)} key={action}>
                        <ListItemText primary={action}/>
                    </ListItem>
                ))}

            </List>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(CounterActionsDialog)