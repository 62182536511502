import React from 'react';
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {setTasbeehCount} from "../../../redux/actions/tasbeehs";
import CountActionButton from "./components/CountActionButton";

const Counter = ({tasbeehId}) => {
    const savedTasbeeh = useSelector(state => state.localStorage.savedTasbeehs[tasbeehId]);
    const count = savedTasbeeh?.count
    const dispatch = useDispatch();

    const onAdd = () => {
        let localCount = count || 0;
        dispatch(setTasbeehCount(tasbeehId, localCount + 1));
    };

    const onSubtract = () => {
        let localCount = count || 0;
        dispatch(setTasbeehCount(tasbeehId, localCount > 0 ? (localCount - 1) : 0));
    };

    const onClear = () => {
        dispatch(setTasbeehCount(tasbeehId, 0));
    };

    const onManualEntry = (value) => {
        dispatch(setTasbeehCount(tasbeehId, parseInt(value)));
    };


    return (
        <React.Fragment>
            <CountDiv>
                <CountActionButton
                    onClear={onClear}
                    onManualEntry={onManualEntry}
                    count={count || 0}
                    tasbeehId={tasbeehId}/>
                {count || 0}
            </CountDiv>
            <ButtonsDiv>
                <AddButton
                color={'primary'}
                variant={'contained'}
                onClick={() => {
                    onAdd()
                }}> + </AddButton>
            <br/>
            <SubtractButton
                color={'primary'}
                variant={'outlined'}
                onClick={() => {
                    onSubtract()
                }}> - </SubtractButton>
            </ButtonsDiv>
        </React.Fragment>
    );
};

export default React.memo(Counter);

const AddButton = styled(Button)`
  width: 90%;
  height: 100px;
  & .MuiButton-label{
    font-size: 3em;
  }
`;

const SubtractButton = styled(Button)`
  width: 90%;
  height: 60px;
  & .MuiButton-label{
    font-size: 3em;
  }
`;

const CountDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  flex: 1;
`;

const ButtonsDiv = styled.div`
  width: 100%;
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
