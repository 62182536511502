import React from 'react';
import FallbackErrorBoundary from "../../FallbackErrorBoundary";
import LegacyTasbeehList from "../../LegacyViews/Tasbeeh/TasbeehList";
import TasbeehList from "../../Views/Tasbeeh/TasbeehList";

const TasbeehListComponent = () => {
return (
        <FallbackErrorBoundary
            component={LegacyTasbeehList}
        >
            <TasbeehList/>
        </FallbackErrorBoundary>
    );
};

export default TasbeehListComponent;