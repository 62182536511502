import React, {useState} from 'react';
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import {API_URL} from "../../const";
import {useNavigate} from "@reach/router";
import {useDispatch, useSelector} from "react-redux";
import {toggleFavouriteTasbeeh} from "../../../redux/actions/localStorage";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import IntentionCapture from "../../components/IntentionCapture";
import CreateNewSubmitButton from "../../components/CreateNewSubmitButton";
import {setSnackbarError} from "../../../redux/actions/notistack";

const NewTasbeeh = () => {
    const [intention, setIntention] = useState("");
    const [tasbeeh, setTasbeeh] = useState("");
    const [target, setTarget] = useState(100);
    const userId = useSelector(state => state.localStorage.userId);
    const userName = useSelector(state => state.localStorage.userName);
    const loading = useSelector(state => state.network.activeRequests);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = () => {
        let payload = {
            "action": "create",
            "type": "tasbeeh",
            "description": intention,
            "target": target,
            "tasbeeh": tasbeeh,
            "user_id": userId,
            "user": userName,
            "repeat": false
        }
        fetch(API_URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then((r) => r.json())
            .then((result) => {
                if (result.new_tasbeeh_id) {
                    dispatch(toggleFavouriteTasbeeh(result.new_tasbeeh_id, intention))
                    navigate(`/tasbeeh/${result.new_tasbeeh_id}`)
                } else {
                    dispatch(setSnackbarError("Failed to create Tasbeeh"));
                }
            })
    }

    const disableSubmit = () => {
        return !intention || intention.length < 4 || !target || target < 0 || loading > 0
    }

    return (
        <Container className="DarkMode">
            <Header title={"New Tasbeeh"} back={'/tasbeeh'}/>

            <ContentContainer className="DarkMode">
                <IntentionCapture intention={intention} setIntention={setIntention} type={"Tasbeeh"}/>
                <TextInputGroup>
                    <Title>Please enter the tasbeeh to recite</Title>
                    <WideText
                        id="outlined-basic"
                        label="Tasbeeh"
                        error={!!tasbeeh && tasbeeh.length < 4}
                        helperText={!!tasbeeh && tasbeeh.length < 4 ? "Please enter at least 4 characters" : ""}
                        color={"secondary"}
                        multiline={true}
                        rows={3}
                        variant="outlined"
                        value={tasbeeh}
                        onChange={(e) => setTasbeeh(e.target.value)}
                    />
                </TextInputGroup>
                <TextInputGroup>
                    <Title>Please enter the target</Title>
                    <Subtitle>(The number of times the Tasbeeh should be recited)</Subtitle>
                    <WideText
                        id="outlined-basic"
                        error={target < 0}
                        helperText={target < 0 ? "Please enter a value greater than 0" : ""}
                        label="Target"
                        color={"secondary"}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={target}
                        onChange={(e) => setTarget(e.target.value)}
                    />
                </TextInputGroup>
                <br/>
                <CreateNewSubmitButton disabled={disableSubmit()} onSubmit={onSubmit}/>
            </ContentContainer>
            <Footer/>
        </Container>
    );
};

export default NewTasbeeh;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    margin: 20px 0;
`;

const TextInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
`;

const WideText = styled(TextField)`
  width: 100%
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;

const Subtitle = styled.p`
  text-align: center;
  width: 90%;
  font-size: 0.6em;
`;