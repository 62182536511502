import React from 'react';
import '../App.css';
import Quraan from "../Views/Quraan/Quraan";
import {Redirect, Router} from "@reach/router"
import styled from "styled-components";
import NewQuraan from "../Views/Quraan/NewQuraan";
import NewTasbeeh from "../Views/Tasbeeh/NewTasbeeh";
import Tasbeeh from "../Views/Tasbeeh/Tasbeeh";
import Settings from "../Views/Settings/Settings";
import Help from "../Views/help";
import Privacy from "../Views/privacy";
import GroupsList from "../Views/Groups/GroupList";
import Group from "../Views/Groups/Group";
import QuraanListComponent from "../Views/Quraan/QuraanListComponent";
import TasbeehListComponent from "../Views/Tasbeeh/TasbeehListComponent";
import Download from "../Views/download";

function ApplicationRoutes() {

    return (
        <StyledRouter>
            <QuraanListComponent default path="/quraan"/>
            <NewQuraan path="/quraan/new"/>
            <TasbeehListComponent path="/tasbeeh"/>
            <NewTasbeeh path="/tasbeeh/new"/>
            <Tasbeeh path="/tasbeeh/:tasbeehId"/>
            <Tasbeeh path="/tasbeeh/:tasbeehId/:page"/>
            <Quraan path="/quraan/:quraanId"/>
            <GroupsList path="/group"/>
            <Group path={"/group/:groupId"}/>
            <Help path={"/help"}/>
            <Privacy path={"/privacy"}/>
            <Settings path={"/settings"}/>
            <Download path={"/download"}/>
            <Redirect noThrow from="/" to="quraan" />
        </StyledRouter>

    );
}

export default ApplicationRoutes;

const StyledRouter = styled(Router)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
`;