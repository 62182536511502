import {API_POST, networkEnded, networkStarted} from "../actions/api";
import {API_URL} from "../../react/const";

export default function apiMiddleware({getState, dispatch}) {
    return next => action => {
        next(action)

        if (action.type === API_POST) {
            dispatch(networkStarted())
            fetch(API_URL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(action.payload.data)
            })
                .then((response) => {
                    return new Promise((resolve) => response.json()
                        .then((json) => resolve({
                            status: response.status,
                            ok: response.ok,
                            json,
                        })));
                }).then(({status, json, ok}) => {
                dispatch(networkEnded())
                if (!ok && action.payload.failure && status !== 404)
                    dispatch(action.payload.failure(json))
                else {
                    if (action.payload.success)
                        dispatch(action.payload.success(json))
                }
            })
                .catch((e) => {
                    dispatch(networkEnded())
                    if (action.payload.failure)
                        dispatch(action.payload.failure(e))
                })
        }
    }
}