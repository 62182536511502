import React from 'react';
import {getMobileOperatingSystem} from "../../../utils/getOS";
import PlayStoreBadge from "../../../components/PlayStoreBadge";
import AppStoreBadge from "../../../components/AppStoreBadge";
import styled from "styled-components";

const ShowAppStoreBadge = ({title}) => {
    let os = getMobileOperatingSystem();

    if (['Android', 'iOS'].indexOf(os) < 0)
        return (
            <Container>
                <Title>{title}</Title>
                <Title>{"The Group Khatam Tracker App is currently only available on the Google Play Store and " +
                "the Apple App Store"}</Title>
                <AppStoreBadge/>
                <br/>
                <PlayStoreBadge/>
            </Container>
        );

    if (os === 'Android')
        return (
            <Container>
                <Title>{title}</Title>
                <PlayStoreBadge/>
            </Container>
        )

    if (os === 'iOS')
        return (
            <Container>
                <Title>{title}</Title>
                <br/>
                <AppStoreBadge/>
            </Container>
        )
};

export default ShowAppStoreBadge;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;