import React from 'react';
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {useSelector} from "react-redux";

const HistoryListItem = ({historyItem, setCurrentItem, setActionDialogOpen}) => {
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);
    const userStartedTasbeeh = loadedTasbeeh.created_by_id;

    const onAction = (historyItem) => {
        setCurrentItem(historyItem);
        setActionDialogOpen(true);
    }

    const renderActionButton = (historyItem) => {
        if (!historyItem.user_id && !userStartedTasbeeh)
            return <div/>;

        return (
            <IconButton size={"small"} onClick={() => onAction(historyItem)}>
                <MoreVertIcon/>
            </IconButton>
        )
    }

    return (
        <ListItem key={historyItem.id}>
            <HistoryItemAdded>
                <SavedTasbeehDate>
                    {new Date(historyItem.time).toLocaleDateString()}
                </SavedTasbeehDate>
                <SavedTasbeehTime>
                    {new Date(historyItem.time).toLocaleTimeString()}
                </SavedTasbeehTime>
            </HistoryItemAdded>
            <HistoryItemName>
                {historyItem.name}
            </HistoryItemName>
            <HistoryItemCount>
                {historyItem.count}
            </HistoryItemCount>
            {renderActionButton(historyItem)}
        </ListItem>
    );
};

export default HistoryListItem;

const ListItem = styled.div`
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: grid;
  grid-template-columns: 80px auto auto 40px;
  align-items: center;
  border-top: 1px solid #4a4949;
  overflow: hidden;
  max-height: 80px;
`;

const HistoryItemName = styled.div`
  flex: 3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  padding-left: 10px;
  min-width: 0;
`;

const HistoryItemAdded = styled.div`
  flex: 3;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const HistoryItemCount = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SavedTasbeehDate = styled.div``;

const SavedTasbeehTime = styled.div``;