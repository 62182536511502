import {
    CLEAR_LOADED_QURAAN,
    JUZ_COMPLETE_SUCCESS,
    JUZ_EDIT_SUCCESS,
    JUZ_RESERVE_SUCCESS,
    SET_LOADED_QURAAN
} from "../actions/khatams";
import {CLEAR_LOADED_TASBEEH, SET_LOADED_TASBEEH} from "../actions/tasbeehs";
import {CLEAR_LOADED_GROUP, SET_LOADED_GROUP} from "../actions/groups";

const DEFAULT_STATE = {
    quraan: null,
    juzRef: null,
    tasbeeh: null,
    group: null
};

export default function khatamsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case SET_LOADED_QURAAN:
            return {...state, quraan: action.payload}
        case SET_LOADED_TASBEEH:
            return {...state, tasbeeh: action.payload}
        case SET_LOADED_GROUP:
            return {...state, group: action.payload}
        case CLEAR_LOADED_QURAAN:
            return {...state, quraan: null, juzRef: null}
        case CLEAR_LOADED_TASBEEH:
            return {...state, tasbeeh: null}
        case CLEAR_LOADED_GROUP:
            return {...state, group: null}
        case JUZ_COMPLETE_SUCCESS:
        case JUZ_RESERVE_SUCCESS:
        case JUZ_EDIT_SUCCESS:
            return {...state, juzRef: action.payload.juzNumber}
        default:
            return {...state}
    }
}