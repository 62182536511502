import moment from "moment";

export const getModifiedDateItem = (currentItem) => {
    if (!('modified' in currentItem)) {
        let dateFormat = "DD-MM-YYYY hh:mm:ss";
        let dateToConvert = `${currentItem.addedDate} ${currentItem.addedTime}`.replaceAll("/", "-")

        let convertedDate = moment(dateToConvert)
        if (!convertedDate.isValid()){
            convertedDate = moment(dateToConvert, dateFormat);
            if (!convertedDate.isValid()){
                convertedDate = moment();
            }
        }

        currentItem['modified'] = convertedDate.toISOString();
        if (currentItem['addedDate']) delete currentItem['addedDate']
        if (currentItem['addedTime']) delete currentItem['addedTime']
    }
    return currentItem
}