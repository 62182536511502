import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {IconButton} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import EditIntentionDialog from "./EditIntentionDialog";
import {editQuraanIntention} from "../../redux/actions/khatams";
import {editTasbeehIntention} from "../../redux/actions/tasbeehs";
import {useDispatch, useSelector} from "react-redux";
import {updateSavedItemDescription} from "../../redux/actions/localStorage";
import {TEXT_COLOR_LIGHT} from "../const";

const Intention = ({item, type, prefix = null, groupName=null}) => {
    const userStartedKhatam = item.created_by_id;
    const savedKhatams = useSelector(state => state.localStorage.savedKhatams);
    const savedTasbeehs = useSelector(state => state.localStorage.savedTasbeehs);
    const dispatch = useDispatch();

    const [editOpen, setEditOpen] = useState(false);
    if (!prefix && prefix !== "") prefix = "Intention: "

    useEffect(() => {
        const savedItems = () => {
            if (type === "Khatam") {
                return savedKhatams
            } else if (type === "Tasbeeh") {
                return savedTasbeehs
            }
            return {}
        };

        if (item.id in savedItems() && savedItems()[item.id].description !== item.description) {
            dispatch(updateSavedItemDescription(item.id, type, item.description))
        }
    }, [dispatch, type, savedTasbeehs, savedKhatams, item])

    const renderEditIntention = () => {
        if (!userStartedKhatam) return null;
        if (type === "Group") return null;

        return (
            <EditIconDiv size={'small'} onClick={()=>{setEditOpen(true)}}>
                <StyledEditIcon/>
            </EditIconDiv>
        )
    };

    return (
        <IntentionDiv>
            {renderEditIntention()}
            {prefix} {item.description}
            <EditIntentionDialog
                item={item}
                open={editOpen}
                onClose={()=>{setEditOpen(false)}}
                onSet={type === "Khatam" ? editQuraanIntention : editTasbeehIntention}
            />
        </IntentionDiv>
    );
};

export default Intention;

const IntentionDiv = styled.div`
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  min-height: 62px;
  max-height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  background-color: ${TEXT_COLOR_LIGHT};
`;

const EditIconDiv = styled(IconButton)`
  &.MuiIconButton-root{
      position: absolute;
      top: 0;
      right: 0;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  &.MuiSvgIcon-root{
      font-size: 0.75em;
  }
`;