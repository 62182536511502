import {API_POST} from "./api";

export const KHATAM_SUCCESS = "KHATAM_SUCCESS";
export const KHATAM_FAILURE = "KHATAM_FAILURE";
export const CLEAR_LOADED_QURAAN = "CLEAR_LOADED_QURAAN";
export const JUZ_COMPLETE_SUCCESS = "JUZ_COMPLETE_SUCCESS";
export const JUZ_EDIT_SUCCESS = "JUZ_EDIT_SUCCESS";
export const JUZ_RESERVE_SUCCESS = "JUZ_RESERVE_SUCCESS";
export const SET_LOADED_QURAAN = "SET_LOADED_QURAAN";
export const KHATAM_RESTART_SUCCESS = "KHATAM_RESTART_SUCCESS";
export const CHANGE_QURAAN_INTENTION_SUCCESS = "CHANGE_QURAAN_INTENTION_SUCCESS";
export const KHATAM_OPENED = "KHATAM_OPENED";
export const LOAD_KHATAMS = "LOAD_KHATAMS";
export const KHATAM_REMOVED_SUCCESSFULLY = "KHATAM_REMOVED_SUCCESSFULLY";

export const setLoadedQuraan = (quraan) => ({type: SET_LOADED_QURAAN, payload: quraan});
export const clearLoadedQuraan = () => ({type: CLEAR_LOADED_QURAAN})
export const khatamOpened = (khatam) => ({type: KHATAM_OPENED, payload: khatam})
export const loadKhatams = () => ({type: LOAD_KHATAMS})

const onSuccess = (payload) => ({
    type: KHATAM_SUCCESS,
    payload
})

const onJuzCompleteSuccess = (payload) => ({
    type: JUZ_COMPLETE_SUCCESS,
    payload
})

const onChangeQuraanIntentionSuccess = (payload) => ({
    type: CHANGE_QURAAN_INTENTION_SUCCESS,
    payload
})


const onKhatamRestartSuccess = (payload) => ({
    type: KHATAM_RESTART_SUCCESS,
    payload
})


const onJuzEditSuccess = (payload) => ({
    type: JUZ_EDIT_SUCCESS,
    payload
})

const onJuzReserveSuccess = (payload) => ({
    type: JUZ_RESERVE_SUCCESS,
    payload
})

const onFailure = (payload) => ({
    type: KHATAM_FAILURE,
    payload
})

export const fetchKhatam = (quraanId, userId, shouldSubscribe) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "get",
            "type": "quraan",
            "user_id": userId,
            "id": quraanId,
            'should_subscribe': shouldSubscribe
        },
        success: (result) => onSuccess({result, quraanId}),
        failure: (r) => onFailure(r)
    }
})

export const editJuz = (quraanId, user, userId, juzNumber) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "edit",
            "type": "quraan",
            "id": quraanId,
            "user": user,
            "user_id": userId,
            "juz": juzNumber
        },
        success: (result) => onJuzEditSuccess({result, juzNumber, quraanId}),
        failure: (r) => onFailure(r)
    }
})

export const completeJuz = (quraanId, user, userId, juzNumber) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "complete",
            "type": "quraan",
            "id": quraanId,
            "user": user,
            "user_id": userId,
            "juz": juzNumber
        },
        success: (result) => onJuzCompleteSuccess({result, juzNumber, quraanId}),
        failure: (r) => onFailure(r)
    }
})

export const reserveJuz = (reserve, quraanId, user, userId, juzNumber) => ({
    type: API_POST,
    payload: {
        data: {
            "action": reserve ? "reserve" : "unreserve",
            "type": "quraan",
            "id": quraanId,
            "user": user,
            "user_id": userId,
            "juz": juzNumber
        },
        success: (result) => onJuzReserveSuccess({result, juzNumber, reserve, quraanId}),
        failure: (r) => onFailure(r)
    }
})

export const restartKhatam = (quraanId, userId) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "restart",
            "type": "quraan",
            "user_id": userId,
            "id": quraanId
        },
        success: (result) => onKhatamRestartSuccess({result}),
        failure: (r) => onFailure(r)
    }
})

export const editQuraanIntention = (userId, quraanId, intention) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "edit_intention",
            "type": "quraan",
            "user_id": userId,
            "id": quraanId,
            "intention": intention
        },
        success: (result) => onChangeQuraanIntentionSuccess({result, quraanId}),
        failure: (r) => onFailure(r)
    }
})
