const BETA_API_URL = "https://beta-api.quraan.io";
const PROD_API_URL = "https://api.quraan.io";
export const NOT_FOUND = "NOT_FOUND";

export const API_URL = process.env.REACT_APP_MODE === 'beta' ? BETA_API_URL : PROD_API_URL;

export const PRIMARY_THEME_COLOR = "#17a3ab";
export const TEXT_COLOR_LIGHT = "#147582";

export const juzIndex = {
    "01": {"englishName": "Alif Laam Meem", "arabicName": "الم"},
    "02": {"englishName": "Sayaqool", "arabicName": "سَيَقُولُ"},
    "03": {"englishName": "Tilkal Rusul", "arabicName": "تِلْكَ الرُّسُلُ"},
    "04": {"englishName": "Lan Tanaa Lu", "arabicName": "لَنْ تَنَالُوا"},
    "05": {"englishName": "Wal Muhsanaat", "arabicName": "وَالْمُحْصَنَاتُ"},
    "06": {"englishName": "Laa Yuhibbullahu", "arabicName": "لَا يُحِبُّ اللَّهُ"},
    "07": {"englishName": "Wa Itha Samioo", "arabicName": "وَإِذَا سَمِعُوا"},
    "08": {"englishName": "", "arabicName": "وَلَوْ أَنَّنَا"},
    "09": {"englishName": "", "arabicName": "قَالَ الْمَلَأُ"},
    "10": {"englishName": "", "arabicName": "وَاعْلَمُوا"},
    "11": {"englishName": "", "arabicName": "يَعْتَذِرُونَ"},
    "12": {"englishName": "", "arabicName": "وَمَا مِنْ دَابَّةٍ"},
    "13": {"englishName": "", "arabicName": "وَمَا أُبَرِّئُ"},
    "14": {"englishName": "", "arabicName": "رُبَمَا"},
    "15": {"englishName": "", "arabicName": "سُبْحَانَ الَّذِي"},
    "16": {"englishName": "", "arabicName": "قَالَ أَلَمْ"},
    "17": {"englishName": "", "arabicName": "اقْتَرَبَ"},
    "18": {"englishName": "", "arabicName": "قَدْ أَفْلَحَ"},
    "19": {"englishName": "", "arabicName": "وَقَالَ الَّذِينَ"},
    "20": {"englishName": "", "arabicName": "أَمَّنْ خَلَقَ"},
    "21": {"englishName": "", "arabicName": "اتْلُ مَا أُوحِيَ"},
    "22": {"englishName": "", "arabicName": "وَمَنْ يَقْنُتْ"},
    "23": {"englishName": "", "arabicName": "وَمَا لِيَ"},
    "24": {"englishName": "", "arabicName": "فَمَنْ أَظْلَمُ"},
    "25": {"englishName": "", "arabicName": "إِلَيْهِ يُرَدُّ"},
    "26": {"englishName": "", "arabicName": "حم"},
    "27": {"englishName": "", "arabicName": "قَالَ فَمَا خَطْبُكُمْ"},
    "28": {"englishName": "", "arabicName": "قَدْ سَمِعَ اللَّهُ"},
    "29": {"englishName": "", "arabicName": "تَبَارَكَ الَّذِي"},
    "30": {"englishName": "", "arabicName": "عَمَّ يَتَسَاءَلُونَ"}
}

export const quraanChapters = [
    {"englishName": "Alif Laam Meem", "arabicName": "الم", "number": "01"}, {
    "englishName": "Sayaqool",
    "arabicName": "سَيَقُولُ",
    "number": "02"
}, {"englishName": "Tilkal Rusul", "arabicName": "تِلْكَ الرُّسُلُ", "number": "03"}, {
    "englishName": "Lan Tanaa Lu",
    "arabicName": "لَنْ تَنَالُوا",
    "number": "04"
}, {"englishName": "Wal Muhsanaat", "arabicName": "وَالْمُحْصَنَاتُ", "number": "05"}, {
    "englishName": "Laa Yuhibbullahu",
    "arabicName": "لَا يُحِبُّ اللَّهُ",
    "number": "06"
}, {"englishName": "Wa Itha Samioo", "arabicName": "وَإِذَا سَمِعُوا", "number": "07"}, {
    "englishName": "",
    "arabicName": "وَلَوْ أَنَّنَا",
    "number": "08"
}, {"englishName": "", "arabicName": "(1) قَالَ الْمَلَأُ", "number": "09"}, {
    "englishName": "",
    "arabicName": "وَاعْلَمُوا",
    "number": "10"
}, {"englishName": "", "arabicName": "يَعْتَذِرُونَ", "number": "11"}, {
    "englishName": "",
    "arabicName": "وَمَا مِنْ دَابَّةٍ",
    "number": "12"
}, {"englishName": "", "arabicName": "(1) وَمَا أُبَرِّئُ", "number": "13"}, {
    "englishName": "",
    "arabicName": "(1) رُبَمَا",
    "number": "14"
}, {"englishName": "", "arabicName": "(1) سُبْحَانَ الَّذِي", "number": "15"}, {
    "englishName": "",
    "arabicName": "(1) قَالَ أَلَمْ",
    "number": "16"
}, {"englishName": "", "arabicName": "(1) اقْتَرَبَ", "number": "17"}, {
    "englishName": "",
    "arabicName": "قَدْ أَفْلَحَ",
    "number": "18"
}, {"englishName": "", "arabicName": "(2) وَقَالَ الَّذِينَ", "number": "19"}, {
    "englishName": "",
    "arabicName": "أَمَّنْ خَلَقَ",
    "number": "20"
}, {"englishName": "", "arabicName": "(1) اتْلُ مَا أُوحِيَ", "number": "21"}, {
    "englishName": "",
    "arabicName": "وَمَنْ يَقْنُتْ",
    "number": "22"
}, {"englishName": "", "arabicName": "(1) وَمَا لِيَ", "number": "23"}, {
    "englishName": "",
    "arabicName": "(1) فَمَنْ أَظْلَمُ",
    "number": "24"
}, {"englishName": "", "arabicName": "إِلَيْهِ يُرَدُّ", "number": "25"}, {
    "englishName": "",
    "arabicName": "حم",
    "number": "26"
}, {"englishName": "", "arabicName": "(1) قَالَ فَمَا خَطْبُكُمْ", "number": "27"}, {
    "englishName": "",
    "arabicName": "قَدْ سَمِعَ اللَّهُ",
    "number": "28"
}, {"englishName": "", "arabicName": "تَبَارَكَ الَّذِي", "number": "29"}, {
    "englishName": "",
    "arabicName": "(2) عَمَّ يَتَسَاءَلُونَ",
    "number": "30"
}]
