import React from 'react';
import ShareIcon from "@material-ui/icons/Share";
import styled from "styled-components";
import {useLocation} from "@reach/router";

const ShareButton = ({type, item}) => {
    const location = useLocation();
    const onShare = () => {
        if (navigator.share) {
            navigator.share({
                title: `Share this ${type}`,
                text: item.description,
                url: location.href.replace('beta.', 'www.')
            })
                .then(() => console.log('Successful share! 🎉'))
                .catch(err => console.error(err));
        }
        ;
    }

    if (!!navigator.share)
        return (
            <ShareDiv onClick={() => onShare()}>
                <ShareIcon/>
            </ShareDiv>
        )
    return null;
};

export default ShareButton;

const ShareDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
font-size: 2em;
padding: 10px;
color: ${({color}) => color ? color : "white"}
`;