import React, {useEffect, useState} from 'react';
import {quraanChapters} from "../../../const";
import styled from "styled-components";
import RenderJuz from "./RenderJuzLine";
import EditNameDialog from "./EditNameDialog";
import ActionsDialog from "./ActionsDialog";
import {useSelector} from "react-redux";
import QuraanFilters from "./QuraanFilters";

const RenderParas = ({quraanId}) => {
    const [dialog, setDialog] = useState(null);
    const [activeJuzNumber, setActiveJuzNumber] = useState(null);
    const loadedQuraan = useSelector(state => state.khatams.quraan);
    const juzRef = useSelector(state => state.khatams.juzRef);
    const [currentFilter, setCurrentFilter] = useState("All");


    useEffect(() => {
        if (juzRef){
            const activeJuz = document.getElementById(`juz-${juzRef}`)
            if (activeJuz){
                activeJuz.scrollIntoView({block: 'center'})
            }
        }
    }, [juzRef]);

   const renderParas = () => {
       return quraanChapters.map((juz) => {
           let juzState;

           if (!(juz.number in loadedQuraan))
               juzState = 'Available';
           else if (juz.number in loadedQuraan && !loadedQuraan[juz.number].completed)
               juzState = "Reserved"
           else juzState = "Complete"

           if (currentFilter === "All" || (juzState === currentFilter))
            return (
                <RenderJuz
                    key={juz.number}
                    juz={juz}
                    quraanId={quraanId}
                    onAction={() => {
                        setActiveJuzNumber(juz.number);
                        setDialog("juzActions")
                    }}/>
            )
           return null
        })
   };

    return (
        <Container>
            <QuraanFilters
                setCurrentFilter={setCurrentFilter}
                currentFilter={currentFilter}
            >
                {renderParas()}
            </QuraanFilters>
            <ActionsDialog
                quraanId={quraanId}
                open={dialog === 'juzActions'}
                setDialog={setDialog}
                setActiveJuzNumber={setActiveJuzNumber}
                juzNumber={activeJuzNumber}
            />
            <EditNameDialog
                quraanId={quraanId}
                open={dialog === 'editName'}
                onClose={() => {
                    setActiveJuzNumber(null);
                    setDialog(null);
                }}
                juzNumber={activeJuzNumber}/>
        </Container>
    )
};

export default React.memo(RenderParas);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
  :last-child {
      border-bottom: 1px solid #4a4949;
  }
`;