import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";

const RepeatSwitch = ({repeat, setRepeat, type}) => {

    return (
        <RepeatDiv>
            {type === 'khatam' && <Title>Allow restart once all Juz have been marked complete?</Title>}
            {type === 'group' && <Title>Allow group members to create Quraan / Tasbeeh Khatams in the group?</Title>}
            <br/>
            <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                        <Switch
                            checked={repeat}
                            onChange={() => setRepeat(prevValue => !prevValue)}
                            color="primary"
                            name="repeat"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Grid>
                    <Grid item>Yes</Grid>
                </Grid>
            </Typography>
        </RepeatDiv>
    );
};

export default React.memo(RepeatSwitch);

const RepeatDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;