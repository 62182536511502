import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import {saveUserName} from "../../../../redux/actions/localStorage";
import {setSnackbarSuccess} from "../../../../redux/actions/notistack";

export default function EditUserNameDialog({open, onClose}) {
    const userName = useSelector(state => state.localStorage.userName);
    const [name, setName] = useState(userName || "");
    const dispatch = useDispatch()

    const handleClose = () => {
        onClose()
    }

    useEffect(()=>{
        setName(userName || "")
    }, [userName])

    const onSubmit = () => {
        dispatch(saveUserName(name));
        dispatch(setSnackbarSuccess("Name changed successfully!"));
        handleClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        error={(name && (name.length > 256 || name.length < 2))}
                        helperText={(name && name.length > 256) ? "Name must be less than 256 characters" :
                        (name && name.length < 3) ? "Name must be at least 3 characters" : ""}
                        margin="dense"
                        id="name"
                        value={name}
                        label="Name"
                        type="name"
                        fullWidth
                        onChange={(e)=>setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={!name || name.length < 2  || name.length > 256} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}