import React from 'react';
import styled from "styled-components";

const TasbeehToRead = ({item}) => {

    if (!item?.tasbeeh)
        return null

    return (
        <React.Fragment>
            <Title>
                Tasbeeh
            </Title>
            <Tasbeeh>
                {item.tasbeeh.split('\n').map((line, index) => <p key={index}>{line}</p>)}
            </Tasbeeh>
        </React.Fragment>
    );
};

export default React.memo(TasbeehToRead);

const Title = styled.div`
  margin: 10px 0;
  padding: 5px;
  flex: 0 1 auto;
  text-align: center;
  background-color: #ffffff18;
  width: 100%;
`;

const Tasbeeh = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1 1 auto;
  padding: 10px;
  text-align: center;
  overflow-y: auto;
`;
