import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {editJuz} from "../../../../redux/actions/khatams";
import {useDispatch, useSelector} from "react-redux";

export default function EditNameDialog({open, quraanId, juzNumber, onClose}) {
    const loadedQuraan = useSelector(state => state.khatams.quraan);
    const userId = useSelector(state => state.localStorage.userId);
    const [name, setName] = useState(loadedQuraan[juzNumber]?.name || "");
    const dispatch = useDispatch()
    const handleClose = () => {
        onClose()
    }

    useEffect(()=>{
        setName(loadedQuraan[juzNumber]?.name || "")
    }, [juzNumber, loadedQuraan])

    const onSubmit = () => {
        dispatch(editJuz(quraanId, name, userId, juzNumber));
        handleClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={name}
                        label="Name"
                        type="name"
                        fullWidth
                        onChange={(e)=>setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={!name || name.length < 2} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}