import React from 'react';
import styled from "styled-components";

const TargetBox = ({title, value, large}) => {
    return (
        <Container>
            <TargetValue large={large}>
                {value}
            </TargetValue>
            <TargetHeading large={large}>{title}</TargetHeading>
        </Container>
    );
};

export default TargetBox;

const Container = styled.div`
    display: flex;
    max-width: 33.3%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TargetHeading = styled.div`
  font-size: ${({large}) => large ? "1em" : "0.6em"};
`;

const TargetValue = styled.div`
  font-size: ${({large}) => large ? "2em" : "1em"};
  padding: 5px;
`;