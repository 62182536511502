import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import {editTasbeeh} from "../../../../redux/actions/tasbeehs";

export default function TasbeehEditNameDialog({open, currentItem, onClose}) {
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);
    const userId = useSelector(state => state.localStorage.userId);
    const [name, setName] = useState("");
    const dispatch = useDispatch();
    const initialName = currentItem?.name;

    useEffect(() => {
        setName(initialName || "")
    }, [open, initialName])

    const onSubmit = () => {
        dispatch(editTasbeeh(loadedTasbeeh.id, currentItem.id, name, userId))
        onClose()

    };

    return (
        <div>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={name}
                        label="Name"
                        type="name"
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={!name || name.length < 2} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}