import {
    SET_SAVED_GROUPS,
    SET_SAVED_KHATAMS,
    SET_SAVED_TASBEEHS,
    SET_USER_ID,
    SET_USER_NAME
} from "../actions/localStorage";

const DEFAULT_STATE = {
    userName: null,
    userId: null,
    savedKhatams: {},
    savedTasbeehs: {},
    savedGroups: {}
};

export default function localStorageReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case SET_USER_NAME:
            return {...state, userName: action.payload}
        case SET_USER_ID:
            return {...state, userId: action.payload}
        case SET_SAVED_KHATAMS:
            return {...state, savedKhatams: {...action.payload}}
        case SET_SAVED_TASBEEHS:
            return {...state, savedTasbeehs: {...action.payload}}
        case SET_SAVED_GROUPS:
            return {...state, savedGroups: {...action.payload}}
        default:
            return {...state}
    }
}