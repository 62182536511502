import {quraanChapters} from "../../const";

export const isCompleted = (loadedQuraan) => {
    if (!loadedQuraan) return false;
    let isCompleted = true;
    quraanChapters.forEach((juz) => {
        if (!(juz.number in loadedQuraan))
            isCompleted = false;
        else {
            if (!loadedQuraan[juz.number].completed)
                isCompleted = false;
        }
    })
    return isCompleted;
};