import React, {useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import HistoryActionsDialog from "./HistoryActionsDialog";
import TasbeehEditNameDialog from "./TasbeehEditNameDialog";
import UserHistory from "./UserHistory";

const sortDate = (a, b) => {
    return (a.time > b.time) ? -1 : ((a.time < b.time) ? 1 : 0);
}

const sortName = (a, b) => {
    return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0);
}

const sortIsUser = (a, b) => {
    return a.user_id ? -1 : b.user_id ? 1 : 0;
}

export const TasbeehHistory = () => {
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);
    const userStartedTasbeeh = loadedTasbeeh.created_by_id;

    const [actionDialogOpen, setActionDialogOpen] = useState(false);
    const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const uniqueUsers = [
        ...new Set((loadedTasbeeh.history || []).sort(sortName).sort(sortDate).sort(sortIsUser).map(item => {
            return `${item.name}_${item.user_identifier}_${item.user_id ? 'true' : 'false'}`
        }))];

    const renderHistory = () => {
        return uniqueUsers.map((userIdentifier, index) => {
            return <UserHistory
                key={userIdentifier}
                isUser={userIdentifier.endsWith("true")}
                userIdentifier={userIdentifier}
                setCurrentItem={setCurrentItem}
                setActionDialogOpen={setActionDialogOpen}/>
        })
    };

    const renderTasbeehHistory = () => {
        if ((loadedTasbeeh.history || []).length < 1)
            return (
                <TasbeehDiv>
                    <Title>There are no submissions</Title>
                </TasbeehDiv>
            );

        return (
            <TasbeehDiv>
                <HistoryActionsDialog
                    open={actionDialogOpen}
                    onClose={() => setActionDialogOpen(false)}
                    onEditName={() => setEditNameDialogOpen(true)}
                    adminUser={userStartedTasbeeh}
                    currentItem={currentItem}
                />
                <TasbeehEditNameDialog
                    open={editNameDialogOpen}
                    onClose={() => setEditNameDialogOpen(false)}
                    currentItem={currentItem}
                    adminUser={userStartedTasbeeh}
                />
                <TasbeehItemsDiv>
                    {renderHistory()}
                    <LastChild/>
                </TasbeehItemsDiv>
            </TasbeehDiv>
        );
    };

    return renderTasbeehHistory()
}

export default React.memo(TasbeehHistory);

const Title = styled.p`
  text-align: center;
  width: 90%;
`;

const TasbeehItemsDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
`;

const LastChild = styled.div`
  width: 100%;
  border-bottom: 1px solid #4a4949;
`;

const TasbeehDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: center;
`;

