import React from 'react';
import styled from "styled-components";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useNavigate} from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from '@material-ui/icons/Help';
import {PRIMARY_THEME_COLOR} from "../const";

const Header = (props) => {
    const {title, back, children} = props;
    const navigate = useNavigate();

    const renderBackButton = () => {
        return (
            <BackButton size="small" onClick={() => navigate(back === true ? -1 : back)}>
                <ArrowBackIcon style={{padding: "10px 0 10px 10px", fontSize: "1.5em", color:"white"}}/>
            </BackButton>
        )
    };

    const renderHelpButton = () => {
        if (title === "Help") return null;
        return (
            <BackButton size="small" onClick={() => navigate('/help')}>
                <HelpIcon style={{padding: "10px", fontSize: "1.5em", color:"white"}}/>
            </BackButton>
        )
    };

    return (
        <TitleDiv>
            {back && renderBackButton()}
            <Title paddingLeft={back ? "0px" : "10px"}>{title}</Title>
            {(back && !children) && <PlaceHolder/>}
            {renderHelpButton()}
            {children}
        </TitleDiv>
    );
};

export default Header;

const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    min-height: 62px;
    height: 62px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${PRIMARY_THEME_COLOR};
`;

const Title = styled.p`
  text-align: left;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : "0px"};
  width: 90%;
  color: #fff;
`;

const PlaceHolder = styled.div`
  width: 60px;
`;

const BackButton = styled(IconButton)`
  width: 60px;
`;