import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch} from "react-redux";
import {setSnackbarWarning} from "../../../../redux/actions/notistack";

export default function EntryForSomeoneDialog({open, onClose, onEntryForSomeone}) {
    const [count, setCount] = useState(0);
    const [name, setName] = useState("");

    const dispatch = useDispatch();

    const handleClose = () => {
        setName("");
        setCount(0);
        onClose();
    }

    const isCountValid = () => {
        return /^\d+$/.test(count);
    }

    const onSubmit = () => {
        if (isCountValid() && name && name.length > 2) {
            onEntryForSomeone({name, count: parseInt(count)});
            handleClose();
        } else {
            if (!isCountValid())
                dispatch(setSnackbarWarning("Please enter a valid count"));
            if (!name || name.length < 3)
                dispatch(setSnackbarWarning("Please enter a valid Name"));
        }

    };

    const handleFocus = (event) => event.target.select();

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Enter Tasbeeh count for someone else</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={name}
                        label="Enter the person's Name"
                        type="name"
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                    />
                    <br/>
                    <br/>
                    <TextField
                        onFocus={handleFocus}
                        margin="dense"
                        id="count"
                        value={count}
                        label="Enter their tasbeeh count"
                        type="number"
                        fullWidth
                        onChange={(e) => setCount(e.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={onSubmit}
                        disabled={!name || name.length < 2 || !count || !isCountValid}
                        color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}