import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from "react-redux";

export default function EditIntentionDialog({open, item, onClose, onSet}) {
    const userId = useSelector(state => state.localStorage.userId);
    const [intention, setIntention] = useState(item?.description || "");
    const dispatch = useDispatch()
    const handleClose = () => {
        onClose()
    }

    useEffect(()=>{
        setIntention(item?.description || "")
    }, [item])

    const onSubmit = () => {
        dispatch(onSet(userId, item.id, intention));
        handleClose();
    };

    const isError = () => {
        return !intention || intention.length > 256 || intention.length < 4
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={"xl"}
            >
                <DialogTitle id="form-dialog-title">Edit Intention</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        error={!!intention && (intention.length > 256 || intention.length < 4)}
                        helperText={
                            (!!intention ?
                                ((intention.length > 256) ? "Intention must be less than 256 characters" :
                                    ((intention.length < 4) ? "Intention must be at least 4 characters" : "")) : "")}
                        id="intention"
                        value={intention}
                        label="New Intention"
                        type="intention"
                        fullWidth
                        onChange={(e)=>setIntention(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={isError()} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}