import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useSelector} from "react-redux";
import styled from "styled-components";
import HistoryListItem from "./HistoryListItem";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const sortDate = (a, b) => {
    return (a.time > b.time) ? -1 : ((a.time < b.time) ? 1 : 0);
}

export default function UserHistory({userIdentifier, setCurrentItem, setActionDialogOpen, isUser}) {
    const classes = useStyles();
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);
    const history = loadedTasbeeh.history || [];

    const userEntry = history.find((item => `${item.name}_${item.user_identifier}_${item.user_id ? 'true' : 'false'}` === userIdentifier));
    const userEntries = history.filter((item => `${item.name}_${item.user_identifier}_${item.user_id ? 'true' : 'false'}` === userIdentifier));
    const totalCount = userEntries.reduce((sum, {count}) => sum + parseInt(count), 0)


    return (
        <StyledAccordian>
            <StyledAccordianSummery
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <UserSummary>
                    <Typography className={classes.heading}>
                        {`${userEntry?.name || ""}${isUser ? " (You)" : ""}`}
                    </Typography>
                    <Typography className={classes.heading}>
                        {totalCount}
                    </Typography>
                </UserSummary>

            </StyledAccordianSummery>
            <AccordionDetails>
                <TasbeehItemsDiv>
                    {userEntries.sort(sortDate).map((entry, key) => {
                        return <HistoryListItem
                            key={key}
                            historyItem={entry}
                            setCurrentItem={setCurrentItem}
                            setActionDialogOpen={setActionDialogOpen}
                        />
                    })}
                </TasbeehItemsDiv>
            </AccordionDetails>
        </StyledAccordian>
    );
}

const StyledAccordian = styled(Accordion)`
  &.MuiAccordion-root{
    background-color: inherit;
  }
`;

const StyledAccordianSummery = styled(AccordionSummary)`
`;

const TasbeehItemsDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
`;

const UserSummary = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 100px;
`;