import {
    GROUP_FAILURE,
    GROUP_OPENED,
    GROUP_REMOVED_SUCCESSFULLY,
    GROUPS_LOADED_SUCCESS,
    setLoadedGroup
} from "../actions/groups";
import {enqueueSnackbar} from "../actions/notistack";
import {addFavouriteGroup, removeFavouriteGroup, updateUpdatedAtTime} from "../actions/localStorage";

export default function groupMiddleware({getState, dispatch}) {
    return next => action => {
        next(action)

        const setSnackbarSuccess = (message) => {
            dispatch(enqueueSnackbar({
                message,
                options: {
                    variant: 'success',
                    autoHideDuration: 3000
                }
            }));
        };
        const setSnackbarError = (message) => {
            dispatch(enqueueSnackbar({
                message,
                options: {
                    variant: 'error',
                    autoHideDuration: 3000
                }
            }));
        };

        const handleGroupIdInSavedGroups = (groupId) => {
            let savedGroups = getState().localStorage.savedGroups;
            if (groupId in savedGroups){
                dispatch(removeFavouriteGroup(groupId, "Not Found"))
            }
        };

        const addGroupToSavedItems = (group) => {
            let savedGroups = getState().localStorage.savedGroups;
            if (!(group.id in savedGroups)){
                dispatch(addFavouriteGroup(group.id, group.description))
            }
        };

        const handleNotFoundGroup = (groupId) => {
            handleGroupIdInSavedGroups(groupId);
            dispatch(setLoadedGroup({NOT_FOUND:true}))
        };

        if (action.type === GROUPS_LOADED_SUCCESS) {
            let {result, groupId} = action.payload;
            if (result === 'Not Found!'){
                handleNotFoundGroup(groupId)
            }

            if (result?.group) {
                return dispatch(setLoadedGroup(result.group))
            }
            return;
        }

        if (action.type === GROUP_OPENED) {
            if (action.payload.NOT_FOUND) return;
            addGroupToSavedItems(action.payload);
            dispatch(updateUpdatedAtTime("Group", action.payload.id))
        }

        if (action.type === GROUP_FAILURE) {
            setSnackbarError("Failed to perform action, Please try again!");
        }

        if (action.type === GROUP_REMOVED_SUCCESSFULLY){
            let {groupId, result} = action.payload;

            if (!result.success)
                return setSnackbarError("Failed to remove Group!")

            setSnackbarSuccess("Group removed successfully!")
            return handleNotFoundGroup(groupId)
        }
    }
}