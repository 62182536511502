import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import localStorageReducer from "./reducers/localStorage";
import localStorageMiddleware from "./middleware/localStorage";
import apiMiddleware from "./middleware/api";
import networkReducer from "./reducers/network";
import khatamsReducer from "./reducers/khatams";
import khatamMiddleware from "./middleware/khatams";
import notistack from "./reducers/notistack";
import tasbeehMiddleware from "./middleware/tasbeehs";
import groupMiddleware from "./middleware/groups";
import pushNotificationsMiddleware from "./middleware/pushNotifications";
import pushNotifications from "./reducers/pushNotifications";

const rootReducer = combineReducers({
    localStorage: localStorageReducer,
    network: networkReducer,
    khatams: khatamsReducer,
    notistack,
    pushNotifications
});

const featureMiddleware = [
    localStorageMiddleware,
    khatamMiddleware,
    tasbeehMiddleware,
    groupMiddleware,
    pushNotificationsMiddleware
];

const coreMiddleware = [
    apiMiddleware
];

const middleware = [...featureMiddleware, ...coreMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

window.store = store;

export default store;
