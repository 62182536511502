import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {DialogContent} from "@material-ui/core";
import {completeJuz} from "../../../../redux/actions/khatams";
import styled from 'styled-components';

function CompleteOthersDialog(props) {
    const {quraanId, juzNumber, setDialog} = props;
    const loadedQuraan = useSelector(state => state.khatams.quraan);
    const user = useSelector(state => state.localStorage.userName);
    const userId = useSelector(state => state.localStorage.userId);
    const dispatch = useDispatch()

    const handleClose = () => {
        setDialog(null);
    }

    const onConfirm = () => {
        dispatch(completeJuz(quraanId, user, userId, juzNumber));
        handleClose();
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose} aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle id="simple-dialog-title">Confirm</DialogTitle>
            <DialogContent>
                <StyledP>Juz {juzNumber} is reserved by {loadedQuraan[juzNumber].name}</StyledP>
                <StyledP>Are you sure you want to mark it as complete?</StyledP>
                <StyledP>You cannot undo this action</StyledP>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(CompleteOthersDialog)

const StyledP = styled.p`
`;