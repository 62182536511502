import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from "styled-components";
import useWindowSize from "../../../hooks/useWindowSize";
import {useNavigate} from "@reach/router";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <TabPanelDiv
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {children}
        </TabPanelDiv>
    );
}

const TabPanelDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}


function TasbeehViews({options, tasbeehId, page}) {
    const theme = useTheme();
    const [value, setValue] = React.useState(page ? page - 1 : 0);
    const {height} = useWindowSize();
    const navigate=useNavigate();

    const handleChange = (event, newValue) => {
        navigate(`/tasbeeh/${tasbeehId}/${newValue + 1}`)
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
         navigate(`/tasbeeh/${tasbeehId}/${index + 1}`)
        setValue(index);
    };

    return (
        <Container height={`${height-62}px`}>
            <AppBar position="static" color={"inherit"}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs"
                >
                    {options.map((option, index) => {
                        return (
                            <Tab key={index} label={options[index].label} {...a11yProps(index)} />
                        )
                    })}
                </Tabs>
            </AppBar>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                id={"swipeMe"}
                style={{
                    overflow: "hidden",
                    height: `${height-62-48}px`,
                    margin: '0 0 20px 0'
                }}
                containerStyle={{height: "100%"}}
                slideStyle={{height: "100%"}}
                onChangeIndex={handleChangeIndex}
            >
                {options.map((option, index) => {
                    return (
                        <TabPanel style={{height: "100%"}} id={`tab-panel-${option.label}`} key={index} value={value} index={index} dir={theme.direction}>
                            <option.component tasbeehId={tasbeehId}/>
                        </TabPanel>
                    )
                })}
            </SwipeableViews>
        </Container>
    );
}

export default React.memo(TasbeehViews);

const Container = styled.div`
  width: 100%;
  height: ${({height}) => height};
`;