import React from 'react';
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {setSnackbarWarning} from "../../redux/actions/notistack";

const FavouriteButton = ({type, item, toggle, complete, count}) => {
    const dispatch = useDispatch();
    const savedItems = useSelector(state => state.localStorage[`saved${type}s`]);
    const isFavourite = item.id in savedItems;

    const toggleFavourite = () => {
        if (item.created_by_id && !complete && isFavourite)
            dispatch(setSnackbarWarning(`You cannot remove a ${type} which you have started and hasn't been completed`));
        else if (isFavourite && type === 'Tasbeeh' && count > 0)
            dispatch(setSnackbarWarning("Cannot remove tasbeeh as your current Tasbeeh count has not been added"));
        else
            dispatch(toggle(item.id, item.description))
    };

    if (isFavourite)
            return (
                <FavouriteDiv onClick={()=>toggleFavourite()}>
                    <FavoriteIcon/>
                </FavouriteDiv>
            )

        return (
                <FavouriteDiv onClick={()=>toggleFavourite()}>
                    <FavoriteBorderIcon/>
                </FavouriteDiv>
            )
};

export default FavouriteButton;

const FavouriteDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
font-size: 2em;
padding: 10px;
color: ${({color}) => color ? color : "white"}
`;