import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Header from "./Header";
import styled from "styled-components";
import {useNavigate} from "@reach/router";

const Loading = ({item, type, children}) => {
    const loading = useSelector(state => state.network.activeRequests);
    const [loadingMessage, setLoadingMessage] = useState("Loading...")
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (item && item.NOT_FOUND){
            setLoadingMessage(`The requested ${type} was not found or has been removed`);
        }
        // if (item && item.NOT_FOUND){
        //     dispatch(setSnackbarError(`The requested ${type} was not found or has been removed!`));
        //     navigate(-1)
        // }
    }, [dispatch, type, item, navigate]);

    if (!item || (item && item.NOT_FOUND) || loading > 0)
        return (
            <Container className="DarkMode">
                <Header title={`Loading ${type}`} back/>
                <LoadingDiv>
                    {loadingMessage}
                </LoadingDiv>
            </Container>
        )
    return children
};

export default React.memo(Loading);

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LoadingDiv = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
