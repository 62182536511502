import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {deleteTasbeeh} from "../../../../redux/actions/tasbeehs";

function HistoryActionsDialog({open, onClose, onEditName, adminUser, currentItem}) {
    const dispatch = useDispatch()
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);
    const userId = useSelector(state => state.localStorage.userId);
    let actions;

    if (adminUser)
        actions = [
            "Edit Name",
            "Delete Entry"
        ]
    else
        actions = [
            "Delete Entry"
        ]

    const handleClose = () => {
        onClose();
    }

    const handleListItemClick = (value) => {
        if (value === "Delete Entry") {
            dispatch(deleteTasbeeh(loadedTasbeeh.id, currentItem.id, userId))
            handleClose();
        }
        if (value === "Edit Name") {
            onEditName();
            handleClose();
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Actions</DialogTitle>
            <List>
                {actions.map((action) => (
                    <ListItem onClick={() => handleListItemClick(action)} key={action}>
                        <ListItemText primary={action}/>
                    </ListItem>
                ))}

            </List>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(HistoryActionsDialog)