import React from 'react';
import styled from "styled-components";
import {APP_VERSION} from "../../version";

const MadeBy = () => {
    return (
        <FooterDiv>
            <p>
                Made by Quraan.io <br/>
                🇿🇦 In Johannesburg 🇿🇦<br/>
                Version {APP_VERSION}

            </p>
        </FooterDiv>
    );
};

export default MadeBy;

const FooterDiv = styled.div`
  height: fit-content;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.6em;
  text-align: center;
  background-color: #282c34;
  color: white;
  font-weight: 600;
  overflow: auto;
`;