import React from 'react';
import styled from "styled-components";

const RenderJuz = ({juz}) => {
    return (
        <React.Fragment>
            <JuzNumber>{juz.number}</JuzNumber>
            <JuzName>{juz.arabicName}</JuzName>
        </React.Fragment>
    );
};

export default React.memo(RenderJuz);

const JuzNumber = styled.div`
  margin: auto 5px auto 10px;
  flex: 0.25;
`;

const JuzName = styled.div`
  flex: 1.75;
  text-align: right;
`;