import {API_POST} from "./api";

export const APP_LOADED = "APP_LOADED";
export const REGISTER_PUSH_NOTIFICATION = "REGISTER_PUSH_NOTIFICATION";
export const SHOW_REGISTER_PUSH_NOTIFICATIONS = "SHOW_REGISTER_PUSH_NOTIFICATIONS";

export const appLoaded = () => ({type: APP_LOADED})
export const registerPushNotification = () => ({type: REGISTER_PUSH_NOTIFICATION})
export const showSubscribeToPushNotifications = (payload=true) => ({type: SHOW_REGISTER_PUSH_NOTIFICATIONS, payload})

export const sendSubscription = (user, userId, subscription) => ({
    type: API_POST,
    payload: {
        data: {
            "action": "subscribe",
            "type": "notification",
            "user": user,
            "user_id":userId,
            subscription

        }
    }
})