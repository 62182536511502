import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReserveActionsDialog from "./ReserveActionsDialog";


const ReserveButton = ({loading, onReserve}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <React.Fragment>
            <ReserveActionsDialog
                isOpen={open}
                onClose={handleToggle}
                onReserve={onReserve}
            />
            <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button">
                        <Button variant={"outlined"} onClick={() => onReserve()}
                                disabled={!!loading}>
                            Reserve
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            disabled={!!loading}
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon/>
                        </Button>
                    </ButtonGroup>


                </Grid>
            </Grid>
        </React.Fragment>

    );
};

export default ReserveButton;
