import ReactGA from "react-ga"
import {APP_VERSION} from "../version";

const TRACKING_ID = "UA-181158190-1";

function init() {
    // Enable debug mode on the local development environment
    // const isDev = !process.env.NODE_ENV || process.env.REACT_APP_MODE === "beta"
    ReactGA.initialize(TRACKING_ID, {
        debug: false
    })
    setProperty('dimension1', APP_VERSION)
    setProperty('dimension2', process.env.REACT_APP_MODE)
    setProperty('dimension3', 'Quraan.io Tracker')

}

function sendEvent(payload) {
    ReactGA.event(payload)
}

function sendPageview(path) {
    ReactGA.set({page: path})
    ReactGA.pageview(path)
}

function setProperty(property, value) {
    ReactGA.set({[property]: value})
}

const analytics = {
    init,
    sendEvent,
    sendPageview,
    setProperty
}

export default analytics;
