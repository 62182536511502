import React, {useState} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SubmitAsDialog from "./SubmitAsDialog";

function ReserveActionsDialog({isOpen, onClose, onReserve}) {
    const [asUserDialogOpen, setAsUserDialogOpen] = useState(false);

    const actions = [
        "Myself",
        "Someone else"
    ]

    const handleClose = () => {
        onClose()
    }

    const onDialogClose = (value) => {
        setAsUserDialogOpen(false);
        if (value)
            onReserve(value);
    }

    const handleListItemClick = (value) => {
        if (value === "Myself") {
            handleClose();
            onReserve();
        }
        if (value === "Someone else") {
            handleClose()
            setAsUserDialogOpen(true)
        }
    };

    return (
        <React.Fragment>
            {asUserDialogOpen && <SubmitAsDialog
                open={asUserDialogOpen}
                onClose={onDialogClose}
            />}

            <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={isOpen}>
            <DialogTitle id="simple-dialog-title">Reserve For</DialogTitle>
            <List>
                {actions.map((action) => (
                    <ListItem onClick={() => handleListItemClick(action)} key={action}>
                        <ListItemText primary={action}/>
                    </ListItem>
                ))}

            </List>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        </React.Fragment>

    );
}

export default React.memo(ReserveActionsDialog)