import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {quraanChapters} from "../../../const";

const options = ['All', 'Available', 'Reserved', 'Complete'];


const activeFilterLength = (loadedQuraan, index) => {
        if (index === 0) return 30;
        return quraanChapters.filter((juz) => {
            let juzState;
            if (!(juz.number in loadedQuraan))
                juzState = 'Available';
            else if (juz.number in loadedQuraan && !loadedQuraan[juz.number].completed)
                juzState = "Reserved"
            else juzState = "Complete";
            return juzState === options[index]
        }).length
    }


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    const loadedQuraan = useSelector(state => state.khatams.quraan);

    const renderContent = () => {
        if (activeFilterLength(loadedQuraan, index) === 0) {
            return (
                <TabPanelDiv>
                    <StyledP>There are no Juz available under this filter</StyledP>
                </TabPanelDiv>
            )
        }
        return <TabPanelDiv>{children}</TabPanelDiv>
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && renderContent()}
        </div>
    );
}

const TabPanelDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledP = styled.div`
  padding: 25px;
  max-width: 100%;
  text-align: center;
  font-size: 0.8em;
`;

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}


function QuraanFilters({children, currentFilter, setCurrentFilter}) {
    const theme = useTheme();
    const [value, setValue] = React.useState(options.indexOf(currentFilter));
    const loadedQuraan = useSelector(state => state.khatams.quraan);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentFilter(options[newValue]);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
        setCurrentFilter(options[index]);
    };

    const getLabel = (index) => {
        if (index === 0)
            return options[index]
        return `${options[index]} (${activeFilterLength(loadedQuraan, index)})`
    };

    return (
        <Container>
            <AppBar position="static" color={"inherit"}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {options.map((option, index) => {
                        return (
                            <Tab key={index} label={getLabel(index)} {...a11yProps(index)} />
                        )
                    })}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                id={"swipeMe"}
                style={{
                    maxHeight: "100%",
                    height: "inherit"
                }}
                onChangeIndex={handleChangeIndex}
            >
                {
                    options.map((option, index) => {
                        return (
                            <TabPanel key={index} value={value} index={index} dir={theme.direction}>
                                {children}
                            </TabPanel>
                        )
                    })
                }
            </SwipeableViews>
        </Container>
    );
}

export default React.memo(QuraanFilters);

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;