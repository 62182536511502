import React from 'react';
import styled from "styled-components";

const PlayStoreBadge = () => {
    return (
        <BadgeDiv>
            <A href='https://play.google.com/store/apps/details?id=io.quraan.groupkhatam&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img
                alt='Get it on Google Play'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></A>
            <Subtitle>
                Google Play and the Google Play logo are trademarks of Google LLC.
            </Subtitle>
        </BadgeDiv>

    );
};

export default PlayStoreBadge;

const BadgeDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Subtitle = styled.p`
  font-size: 8px;
`;

const A = styled.a`
  max-width: 90%;
  height: auto;
  width: 290px; 
  
  img {
    width: 100%;
  }
`;