import React from 'react';
import styled from "styled-components";
import BottomTabBar from "./BottomTabBar";

const Footer = (props) => {
    if (props.bottomBar)
        return (
            <FooterDiv bottomBar>
                <p>
                    For the Esaal - e - Sawaab of Nabi صلى الله عليه وسلم and all Marhooms
                </p>
                <BottomTabBar/>
            </FooterDiv>
        );
    return (
        <FooterDiv>
            <p>
                For the Esaal - e - Sawaab of Nabi صلى الله عليه وسلم and all Marhooms
            </p>
        </FooterDiv>
    );
};

export default Footer;

const FooterDiv = styled.div`
  width: 96%;
  height: fit-content;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.6em;
  text-align: center;
  background-color: #282c34;
  color: white;
  font-weight: 600;
  padding: 5px;
  overflow: auto;
  margin-bottom: ${(props => props.bottomBar ? "62px" : "0px")};
`;