import React from 'react';
import styled from "styled-components";

const TimesCompleted = ({item, type}) => {
        if (!item.repeat)
            return null;

        if (!item.times_completed)
            return null;

        if (parseInt(item.times_completed) < 1)
            return null;

        if (parseInt(item.times_completed) === 1)
            return (
                <RepeatsCompletedDiv>
                    {`This ${type} has been completed ${item.times_completed} time`}
                </RepeatsCompletedDiv>
            );

        return (
                <RepeatsCompletedDiv>
                    {`This khatam has been completed ${item.times_completed} times`}
                </RepeatsCompletedDiv>
            );
};

export default TimesCompleted;

const RepeatsCompletedDiv = styled.div`
  max-width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 0.75em;
`;