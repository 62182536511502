import React, {useEffect} from 'react';
import '../../App.css';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {toggleFavouriteTasbeeh} from "../../../redux/actions/localStorage";
import FavouriteButton from "../../components/FavouriteButton";
import ShareButton from "../../components/ShareButton";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import {isCompleted} from "./tasbeehUtils";
import TasbeehViews from "./components/TasbeehViews";
import {clearLoadedTasbeeh, fetchTasbeeh, tasbeehOpened} from "../../../redux/actions/tasbeehs";
import TasbeehPage from "./components/TasbeehPage";
import CounterPage from "./components/CounterPage";
import {TasbeehHistory} from "./components/TasbeehHistory";

export const Tasbeeh = (props) => {
    const tasbeehId = props.tasbeehId;
    const page = props.page;
    const userId = useSelector(state => state.localStorage.userId);
    const loadedTasbeeh = useSelector(state => state.khatams.tasbeeh);
    const savedTasbeehs = useSelector(state => state.localStorage.savedTasbeehs);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTasbeeh(tasbeehId, userId, !savedTasbeehs[tasbeehId]))
        return (() => {
            dispatch(clearLoadedTasbeeh())
        })
    }, [dispatch, tasbeehId, userId]);

    useEffect(() => {
        if (!loadedTasbeeh) return;
        dispatch(tasbeehOpened(loadedTasbeeh));
    }, [dispatch, loadedTasbeeh])

    return (
        <Loading item={loadedTasbeeh} type={"Tasbeeh"}>
            <Container className="DarkMode">
                <Header title={"Tasbeeh"} back={"/tasbeeh"}>
                    <ShareButton item={loadedTasbeeh} type={'Tasbeeh'}/>
                    <FavouriteButton
                        item={loadedTasbeeh}
                        toggle={toggleFavouriteTasbeeh}
                        type={'Tasbeeh'}
                        complete={isCompleted(loadedTasbeeh)}
                        count={savedTasbeehs[tasbeehId]?.count || 0}
                    />
                </Header>
                <TasbeehViews
                    page={page}
                    tasbeehId={tasbeehId}
                    options={[
                        {label: "Tasbeeh", component: TasbeehPage},
                        {label: "Counter", component: CounterPage},
                        {label: "History", component: TasbeehHistory}
                    ]}
                />

            </Container>
        </Loading>
    );
}

export default Tasbeeh;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`;

