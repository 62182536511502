import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {editTasbeehTarget} from "../../../../redux/actions/tasbeehs";

export default function EditTargetDialog({item}) {
    const userId = useSelector(state => state.localStorage.userId);
    const [open, setOpen] = useState(false);
    const [target, setTarget] = useState(item?.description || "");
    const dispatch = useDispatch()
    const userStartedKhatam = item.created_by_id;

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        setTarget(item?.target || "")
    }, [item])

    const onSubmit = () => {
        dispatch(editTasbeehTarget(userId, item.id, target));
        handleClose();
    };

    const renderEditIntention = () => {
        if (!userStartedKhatam) return null;

        return (
            <EditIconDiv size={'small'} onClick={() => {
                setOpen(true)
            }}>
                <StyledEditIcon/>
            </EditIconDiv>
        )
    };

    return (
        <React.Fragment>
            {renderEditIntention()}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={"xl"}
            >
                <DialogTitle id="form-dialog-title">Edit Target</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        error={target < 0}
                        helperText={target < 0 ? "Please enter a value greater than 0" : ""}
                        label="New Target"
                        color={"secondary"}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={target}
                        fullWidth
                        onChange={(e) => setTarget(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={target < 0} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}


const EditIconDiv = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  &.MuiSvgIcon-root {
    font-size: 0.75em;
  }
`;