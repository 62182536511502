import React from 'react';
import '../../App.css';
import styled from "styled-components";
import {useNavigate} from "@reach/router"
import {useSelector} from "react-redux";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ShowAppStoreBadge from "./components/showAppStoreBadge";
import RegisterNotificationListItem from "../../components/registerNotificationListItem";

export const GroupsList = () => {
    const savedGroups = useSelector(state => state.localStorage.savedGroups);

    const navigate = useNavigate();

    const renderSavedGroupsItems = () => {
        let groupList = Object.keys(savedGroups).map((groupId) => {
            let group = savedGroups[groupId];
            group['id'] = groupId;
            return group
        })

        return groupList.sort((a, b) => (new Date(b.modified) - new Date(a.modified)))
            .map((group) => {
                let groupId = group.id;
                return (
                    <ListItem key={groupId} onClick={() => {
                        navigate(`/group/${groupId}`)
                    }}>
                        <SavedGroupsListItem>
                            <SavedGroupsDescription>
                                {savedGroups[groupId].description}
                            </SavedGroupsDescription>
                            <SavedGroupsAdded>
                                <SavedGroupsDate>
                                    {new Date(savedGroups[groupId].modified).toLocaleDateString()}
                                </SavedGroupsDate>
                                <SavedGroupsTime>
                                    {new Date(savedGroups[groupId].modified).toLocaleTimeString()}
                                </SavedGroupsTime>
                            </SavedGroupsAdded>
                        </SavedGroupsListItem>
                    </ListItem>
                );
            })
    };

    const rendersavedGroups = () => {
        if (Object.keys(savedGroups).length < 1)
            return (
                <GroupsDiv>
                    <Header title={'Your Saved Groups'}/>
                    <Title>You do not have any saved groups</Title>
                    <br/>
                    <Title>To join a group, just click on a group link and it will appear here</Title>
                    <ShowAppStoreBadge
                        title={"To create a group, you need to download the Group Khatam Tracker App"}
                    />
                </GroupsDiv>
            );

        return (
            <GroupsDiv>
                <Header title={'Your Saved Groups'}/>
                <JuzDiv>
                    <RegisterNotificationListItem/>
                    {renderSavedGroupsItems()}
                    <LastChild/>
                </JuzDiv>
            </GroupsDiv>
        );
    };

    return (
        <Container className="DarkMode">
            <NewGroupsDiv>
                {rendersavedGroups()}
                <Footer bottomBar/>
            </NewGroupsDiv>
        </Container>
    );
}

export default GroupsList;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const NewGroupsDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;
`;

const Title = styled.p`
  text-align: center;
  width: 90%;
`;


const JuzDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
`;

const LastChild = styled.div`
  width: 100%;
  border-bottom: 1px solid #4a4949;
`;

const GroupsDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: center;
  justify-content: flex-start;
`;

const ListItem = styled.div`
  width: 100%;
  min-height: 80px;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #4a4949;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
`;


const SavedGroupsListItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const SavedGroupsDescription = styled.div`
  flex: 7;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  padding-left: 10px;
  min-width: 0;
`;

const SavedGroupsAdded = styled.div`
  flex: 3;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SavedGroupsDate = styled.div``;

const SavedGroupsTime = styled.div``;