import {SHOW_REGISTER_PUSH_NOTIFICATIONS} from "../actions/pushNotifications";

const DEFAULT_STATE = {
    showNotification: false
};

export default function pushNotifications(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case SHOW_REGISTER_PUSH_NOTIFICATIONS:
            return {...state, showNotification: action.payload}
        default:
            return {...state}
    }
}