import {
    APP_LOADED,
    REGISTER_PUSH_NOTIFICATION, sendSubscription,
    showSubscribeToPushNotifications,
    subscribeUser
} from "../actions/pushNotifications";

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}


export default function pushNotificationsMiddleware({getState, dispatch}) {
    return next => action => {
        next(action)

        const subscribe = (subscription) => {
            let localStorage = getState().localStorage
            dispatch(sendSubscription(localStorage.userName, localStorage.userId, {type: "WebPush", payload: subscription}))
        }

        function subscribeUser() {
            if ('serviceWorker' in navigator) {
                Notification.requestPermission(function (status) {
                    if (status !== 'granted') {
                        console.warn('Permission for notifications is not granted');
                    } else {
                        navigator.serviceWorker.ready.then(function (reg) {
                            reg.pushManager.subscribe({
                                applicationServerKey: convertedVapidKey,
                                userVisibleOnly: true
                            }).then(function (sub) {
                                dispatch(showSubscribeToPushNotifications(false))
                                // We have a subscription, update the database
                                subscribe(JSON.parse(JSON.stringify(sub)))
                            }).catch(function (e) {
                                if (Notification.permission === 'denied') {
                                    console.warn('Permission for notifications was denied');
                                } else {
                                    console.error('Unable to subscribe to push', e);
                                }
                            });
                        })
                    }
                });
            }
        }

        const getSubscriptionStatus = () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.ready.then(function (reg) {
                    reg.pushManager.getSubscription().then(function (sub) {
                        if (sub === null) {
                            // Update UI to ask user to register for Push
                            if (Notification.permission !== "denied")
                                dispatch(showSubscribeToPushNotifications())
                            else
                                subscribeUser()
                            console.log('Not subscribed to push service!');
                        } else {
                            subscribe(JSON.parse(JSON.stringify(sub)))
                        }
                    });
                })
            }
        }

        switch (action.type) {
            case APP_LOADED: {
                if (!!(window.Notification || window.webkitNotifications || navigator.mozNotification))
                    getSubscriptionStatus();
                break;
            }
            case REGISTER_PUSH_NOTIFICATION: {
                subscribeUser()
                break;
            }

            default:
                break;
        }
    }
}