import React, {useState} from 'react';
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Footer from "../components/Footer";

const RegisterUser = (props) => {
    const [name, setName] = useState(localStorage.getItem("userName"));

    const onSubmit = () => {
        props.onSetUser(name)
    }

    return (
        <Container className="DarkMode">
            <Content> As Salaamu Alaikum</Content>
            <br/>
            <Content> Welcome to quraan.io's Group Quraan tracker</Content>
            <br/>
            <Content> This App helps you to co-ordinate Quraan Khatams between a group of people</Content>
            <br/>
            <p>To get started, please enter your name</p>
            <br/>
            <TextField
                id="outlined-basic"
                error={(name && (name.length > 256 || name.length < 2))}
                        helperText={(name && name.length > 256) ? "Name must be less than 256 characters" :
                        (name && name.length < 3) ? "Name must be at least 3 characters" : ""}
                label="Name"
                color={"secondary"}
                variant="outlined"
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
            />
            <br/>
            <Button
                variant="contained"
                color="secondary"
                onClick={()=>onSubmit()}
                disabled={!name || name.length < 2 || name.length > 256}
            >
                Next
            </Button>
            <br/>
            <br/>
            <ContentSubtitle>
                N.B.: We do not store your saved Khatams remotely. Should you change your device or browser,
                you will not be able to access your saved Khatams from another device unless you share the
                Khatam link with your new device
            </ContentSubtitle>
            <Footer/>
        </Container>
    );
};

export default RegisterUser;

const Container = styled.div`
    max-width: 100%;
    padding: 20px;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`;

const Content = styled.p`
    width: 100%;
    text-align: center;
    margin: 2px 10px 2px;
`;

const ContentSubtitle = styled.p`
    width: 100%;
    text-align: center;
    margin: 2px 10px 2px;
    font-size: 0.75em;
`;