import React from 'react';
import styled from "styled-components";
import {APP_VERSION} from "../../version";

const VersionFooter = () => {
    return (
        <FooterDiv>
            <p>
                Version {APP_VERSION}
            </p>
        </FooterDiv>
    );
};

export default VersionFooter;

const FooterDiv = styled.div`
width: 96%;
height: fit-content;
min-height: 40px;
max-height: 40px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 0.6em;
text-align: center;
background-color: #282c34;
color: white;
font-weight: 600;
padding: 5px;
overflow: auto;
`;